import React from 'react'
import styled from 'styled-components'

const TableWrapper = styled.div`
  overflow-x: hidden;
  width: 428px;
  overflow-y: auto;
  height: 908px;

`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`

const Th = styled.th`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`

const Td = styled.td`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`
const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`
const Heading = styled.h2`
  font-size: 1.3em;
  margin-bottom: 10px;
`
const ColumnWrapper = styled.div`
display: flex;
flex-direction: column;
justify-contents: center;
align-items: center;
`
const TableHeader = styled.thead`
  /* Make the header sticky */
  position: sticky;
  top: 0;
  background-color: white; /* Optional: change the background color if needed */
  z-index: 1; /* Ensure the header is above the body content */
`;

const ResponsiveTable = ({ data, caption }) => {
  return (
    <ColumnWrapper>
      <Heading>{caption}</Heading>
      <TableWrapper>
        <Table>
          <TableHeader>
            <Tr>
              <Th>Key Name</Th>
              <Th>Value</Th>
            </Tr>
          </TableHeader>
          {data && (
            <tbody>
              {data?.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.key}</Td>
                  <Td>{item.value}</Td>
                </Tr>
              ))}
            </tbody>
          )}
        </Table>
      </TableWrapper>
    </ColumnWrapper>
  )
}

export default ResponsiveTable
