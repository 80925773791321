import React from 'react'
import styled from 'styled-components'

import { colors, spacing, fontSize, media, device } from '../../../../../theme'

import Position from './Position'
import ToggleButton from '../../../../../controls/ToolBarToggleButton'
import ToolBar from './ToolBar'

import InputFormRow from '../../../../../controls/InputFormRow'
import FormSection from '../../../../../controls/FormSection'
// import InputField from '../../../controls/InputField'
import FormRow from '../../../../../controls/FormRow'
import Popup from '../../../../../controls/Popup'
import ButtonRow from '../../../../../controls/ButtonRow'
import Button from '../../../../../controls/Button'
import InputTextField from '../../../../../controls/InputTextField'
import { useTranslation } from '../../../../../i18n/useTranslation'
import { useJobConfig } from '../../../../JobConfig/JobContext'

const Col = styled.div`
  flex: 1 0 33.33%;
  // padding: 10px;
  min-width: 150px;
  ${media.down(device.mobile)`
    width: 100%;
    max-width: 100%;
    min-width: unset;
    `}
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  // margin: -10px;
  align-items: center;
  margin: 16px 0;
  column-gap: 5px;
  width: 100%;
  label {
    font-size: ${fontSize.sm};
    margin-right: ${spacing(2)};
    width: 100%;
  }
`
const CustomSelect = styled(InputTextField)`
  .formRow__input {
    select {
      max-width: 100%;
    }
  }
`

const PageNumberForm = (props) => {
  const {
    onChange,
    isValid,
    errors,
    bindInput,
    // bindError,
    formValidate,
    margin,
    pageNumberFormat,
    filesReady,
    showHorizontalMargin,
    setPopupOpen,
  } = props
  const t = useTranslation('tool_addPageNumber')
  const hintText = `{pageNumber} : ${t('currentPage')}, {totalPages} : ${t(
    'totalPages'
  )}`
  const { actionConfig } = useJobConfig()
  console.log('actionConfig', actionConfig)
  console.log('errors', errors)
  return (
    <Popup
      size="md"
      onClose={() => setPopupOpen(false)}
      title={t('titlePageSetup')}
      footer={() => {
        return (
          <ButtonRow center noWrap>
            <Button
              color="primary"
              disabled={!isValid}
              onClick={() => setPopupOpen(false)}
            >
              {t('ok')}
            </Button>
            <Button onClick={() => setPopupOpen(false)}>
              {t('btnCancel')}
            </Button>
          </ButtonRow>
        )
      }}
    >
      <FormSection>
        <Row>
          <Col>
            <ToggleButton
              checked={actionConfig?.skipFirstPage}
              label={t('skipFirstPage')}
              name="skipFirstPage"
              onChange={(event) => onChange(event, formValidate)}
            />
          </Col>
          <Col>
            <Position
              label={t('position')}
              errorMessage={errors?.position?.replace(
                '{fieldName}',
                t('position')
              )}
              defaultPosition={actionConfig.position}
              onPosition={(position) => {
                const event = {
                  target: {
                    name: 'position',
                    value: position,
                    type: 'radio',
                  },
                }
                onChange(event, formValidate)
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputTextField
              {...((bindInput && bindInput('margin')) || {})}
              // {...bindError('margin')}
              label={t('margin')}
              inputType="select"
              items={margin}
              disabled={!filesReady}
            />
          </Col>
        </Row>

        {actionConfig.margin === margin[3].value ? (
          <Row>
            {showHorizontalMargin && (
              <Col>
                <InputTextField
                  {...((bindInput && bindInput('marginX')) || {})}
                  // {...bindError('marginX')}
                  label={t('horizontalMargin')}
                  inputType="number"
                  disabled={!filesReady}
                  errorMessage={
                    errors &&
                    errors['marginX'] &&
                    errors['marginX']
                      ?.pop()
                      ?.replace('{fieldName}', t('horizontalMargin'))
                  }
                />
              </Col>
            )}
            <Col>
              <InputTextField
                {...((bindInput && bindInput('marginY')) || {})}
                // {...bindError('marginY')}
                label={t('verticalMargin')}
                inputType="number"
                disabled={!filesReady}
                errorMessage={
                  errors &&
                  errors['marginY'] &&
                  errors['marginY']
                    ?.pop()
                    ?.replace('{fieldName}', t('verticalMargin'))
                }
              />
            </Col>
          </Row>
        ) : (
          ''
        )}

        <Row>
          <Col>
            <CustomSelect
              {...((bindInput && bindInput('pageNumberFormat')) || {})}
              // {...bindError('pageNumberFormat')}
              label={t('pageNumberFormat')}
              inputType="select"
              items={pageNumberFormat}
              disabled={!filesReady}
            />
          </Col>
        </Row>

        {actionConfig.pageNumberFormat === pageNumberFormat[3].value ? (
          <Row>
            <Col>
              <InputTextField
                {...((bindInput && bindInput('customText')) || {})}
                // {...bindError('customText')}
                label={t('customText')}
                inputType="text"
                disabled={!filesReady}
                hint={hintText}
                errorMessage={
                  errors &&
                  errors['customText'] &&
                  errors['customText']
                    ?.pop()
                    ?.replace('{fieldName}', t('customText'))
                }
              />
            </Col>
          </Row>
        ) : (
          ''
        )}

        <Row>
          <ToolBar
            filesReady={filesReady}
            values={actionConfig}
            onChange={onChange}
          />
        </Row>
      </FormSection>
    </Popup>
  )
}

export default PageNumberForm
