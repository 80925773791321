import { useState } from 'react'
import styled from 'styled-components'

import Accordion from '../../../components/Accordion'
import InputField from '../../../controls/InputField'
import Button from '../../../controls/Button'
import ToggleButton from '../../../controls/ToggleButton'
import PToggleButton from './Popup/ToggleButton'
import ToolTip from '../../../controls/ToolTip'
import plusIcon from '../../../controls/icons/plus'
import deleteIcon from '../../../controls/icons/trash'
import copyIcon from '../../../controls/icons/copy'
import { SketchPicker } from 'react-color'
import FormRow from '../../../controls/FormRow'
import { colors, media, device } from '../../../theme'
import { copyTextToClipboard } from '../../../utils/copyTextToClipboard'

export const getEmptyKey = (currentPage = 1) => {
  return {
    keyname: 'BoxName',
    pages: currentPage,
    fillColor: '#ffffffff',
    position: {
      x1: 100,
      y1: 100,
      x2: 200,
      y2: 200,
    },
  }
}
const PositionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  input {
    width: 100px;
  }
`

const SketchBarcodeColor = styled.div`
  position: absolute;
  z-index: 1;
  ${media.down(device.mobile)`
  right: 5px;
  `}
  .sketch-picker {
    > div:first-child {
      padding-bottom: 50% !important;
    }
    input {
      height: auto;
    }
  }
`
const ColorBox = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${(prop) => prop.color};
  border: 1px solid black;
`
const FullWidth = styled.div`
  position: relative;
  width: 100%;
`
const FlexDiv = styled.div`
  display: flex;
`
export default ({ configInfo, setConfigInfo, pageNum }) => {
  const [edit, setEdit] = useState(true)
  const [showFillSketch, setShowFillSketch] = useState(false)

  const setKeyValue = (e, index) => {
    if (e && e.target) {
      const _configInfo = { ...configInfo }
      _configInfo.keys[index][e.target.name] = e.target.value
      if (e.target.name === 'searchWholePage') {
        _configInfo.keys[index].searchWholePage = e.target.checked
      }
      setConfigInfo(_configInfo)
    }
  }

  const setPositionValue = (e, index) => {
    if (e && e.target) {
      const _configInfo = { ...configInfo }
      _configInfo.keys[index]['position'][e.target.name] =
        e.target.value > -1 ? e.target.value : 0

      switch (e.target.name) {
        case 'x1':
          _configInfo.keys[index]['position'].x1 = Math.min(
            _configInfo.keys[index]['position'].x1,
            _configInfo.keys[index]['position'].x2
          )
          break
        case 'y1':
          _configInfo.keys[index]['position'].y1 = Math.min(
            _configInfo.keys[index]['position'].y1,
            _configInfo.keys[index]['position'].y2
          )
          break
        case 'x2':
          _configInfo.keys[index]['position'].x2 = Math.max(
            _configInfo.keys[index]['position'].x1,
            _configInfo.keys[index]['position'].x2
          )
          break
        case 'y2':
          _configInfo.keys[index]['position'].y2 = Math.max(
            _configInfo.keys[index]['position'].y1,
            _configInfo.keys[index]['position'].y2
          )
          break
        default:
          break
      }
      setConfigInfo(_configInfo)
    }
  }

  const addKey = () => {
    const _configInfo = { ...configInfo }
    if (!_configInfo.keys) {
      _configInfo.keys = []
    }
    let key = getEmptyKey(pageNum)
    let i = 1
    while (_configInfo.keys.find((k) => k.keyname === key.keyname)) {
      key.keyname = 'BoxName_' + i
      i++
    }
    _configInfo.keys.push(key)
    setConfigInfo(_configInfo)
  }

  const deleteKey = (position) => {
    const _configInfo = { ...configInfo }
    _configInfo.keys.splice(position, 1)
    setConfigInfo(_configInfo)
  }

  const padLeftZero = (n, padLength) => {
    const z = '0'
    n = n + ''
    return n.length >= padLength
      ? n
      : new Array(padLength - n.length + 1).join(z) + n
  }

  // const onTemplateChange = (e) => {
  //   if (e && e.target) {
  //     const fileStorageId = e.target.value
  //     const _template = testTemplateList.find(
  //       (t) => t.fileStorageId === fileStorageId
  //     )
  //     setTestTemplateFile(_template)
  //   }
  // }
  // const templateSelectList = []

  // if (testTemplateList) {
  //   testTemplateList.forEach((t) => {
  //     templateSelectList.push({
  //       value: t.fileStorageId,
  //       text: t.fileName,
  //     })
  //   })
  // }

  return (
    <div>
      <h2>Redact Edit</h2>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>Keys</h3>
        <ToolTip content="Add Keys">
          <Button
            type="button"
            color="secondary"
            variant="icon"
            startIcon={plusIcon}
            onClick={addKey}
          />
        </ToolTip>
      </div>
      {configInfo &&
        configInfo.keys &&
        configInfo.keys.map((k, index) => (
          <Accordion key={index} title={k.keyname}>
            <div>
              <Button
                variant="icon"
                startIcon={deleteIcon}
                color={'parse_color_icon'}
                onClick={() => {
                  deleteKey(index)
                }}
              />
              <FormRow label={'Fill color'} className={'redact_color_position'}>
                <FullWidth>
                  <PToggleButton
                    onClick={() => {
                      if (showFillSketch) setShowFillSketch(false)
                      else setShowFillSketch(true)
                    }}
                    pressed={showFillSketch}
                  >
                    <FlexDiv>
                      &nbsp;
                      <ColorBox color={k.fillColor} />
                      &nbsp;
                      {k.fillColor}
                    </FlexDiv>
                  </PToggleButton>

                  {showFillSketch && (
                    <SketchBarcodeColor
                      onBlur={() => {
                        setShowFillSketch('')
                      }}
                    >
                      <SketchPicker
                        color={k.fillColor}
                        onChangeComplete={({ hex, rgb }) => {
                          const { a } = rgb
                          const _hex =
                            (hex === 'transparent' ? '#000000' : hex) +
                            padLeftZero(parseInt(a * 255, 10).toString(16), 2)
                          const e = {
                            target: {
                              name: 'fillColor',
                              value: _hex,
                              type: 'radio',
                            },
                          }
                          setKeyValue(e, index)
                        }}
                        width={230}
                      />
                    </SketchBarcodeColor>
                  )}
                </FullWidth>
              </FormRow>

              <InputField
                label="Pages"
                name="pages"
                inputType="text"
                value={k.pages}
                placeholder="Pages"
                readonly={!edit}
                onChange={(e) => {
                  e.target.value = pageNum
                  setKeyValue(e, index)
                }}
              />
              {k.position && (
                <>
                  <h4>Position</h4>
                  <PositionWrapper>
                    <InputField
                      label="X1"
                      name="x1"
                      inputType="number"
                      value={k.position.x1 ?? 0}
                      placeholder="X1 Position"
                      readonly={!edit}
                      onChange={(e) => {
                        setPositionValue(e, index)
                      }}
                    />
                    <InputField
                      label="Y1"
                      name="y1"
                      inputType="number"
                      value={k.position.y1 ?? 0}
                      placeholder="Y1 Position"
                      readonly={!edit}
                      onChange={(e) => {
                        setPositionValue(e, index)
                      }}
                    />
                  </PositionWrapper>
                  <PositionWrapper>
                    <InputField
                      label="X2"
                      name="x2"
                      inputType="number"
                      value={k.position.x2 ?? 0}
                      placeholder="X2 Position"
                      readonly={!edit}
                      onChange={(e) => {
                        setPositionValue(e, index)
                      }}
                    />
                    <InputField
                      label="Y2"
                      name="y2"
                      inputType="number"
                      value={k.position.y2 ?? 0}
                      placeholder="Y2 Position"
                      readonly={!edit}
                      onChange={(e) => {
                        setPositionValue(e, index)
                      }}
                    />
                  </PositionWrapper>
                </>
              )}
            </div>
          </Accordion>
        ))}
    </div>
  )
}
