import styled from 'styled-components'
import { device, media } from '../../theme'

export const ParseForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, auto));
  column-gap: 20px;
  margin: 24px;
  height: ${(props) => props.$height};
  overflow: auto;

  ${media.down(device.desktop)`
   grid-template-columns: 1fr; 
        grid-template-rows: auto auto; 
  `}
  ${media.down(device.mobile)`
   grid-template-columns: 280px; 
        grid-template-rows: auto auto; 
  `}
`
export const FormWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  ${media.down(device.desktop)`
     overflow-x: auto;
    `}
`
export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 10px;
  height: ${(props) => props.$height};
  overflow: auto;
`
export const BtnWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`
