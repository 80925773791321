import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import { useMessage, withMessageBox } from '../../controls/MessageBox'

import thumbnailsActions from '../../store/thumbnails/actions'
import { startPdfJob } from '../../store/notification/action'
import hubActions from '../../store/hub/actions'

import EditPages from '../../components/EditPages'
import jobConfig from '../../configuration/jobConfig'
import { MessageType } from '../../utils/enums'
import { useTranslation } from '../../i18n/useTranslation'
import { TeamsFxContext } from '../../components/TeamFxContextProvider'

const OrganizePdf = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { connectionId } = useSelector((state) => state.hub)
  const { showMessage } = useMessage()
  const [actionConfig, setActionConfig] = useState({})
  const { state } = location
  const { docIdExt, tool } = state
  const t = useTranslation()
  const { teamsUserCredential } = useContext(TeamsFxContext)

  const _inputFiles = useSelector((state) =>
    state.notification.find((e) => e?.docIdExt === docIdExt)
  )
  const inputFiles = Array.isArray(_inputFiles) ? _inputFiles : [_inputFiles]

  useEffect(() => {
    const configs =
      tool && jobConfig[tool.toolName] && jobConfig[tool.toolName].jobConfig
        ? jobConfig[tool.toolName].jobConfig
        : {}
    setActionConfig(configs)
  }, [])

  const { thumbnails } = useSelector((state) => state.thumbnails)

  const startDocProcessing = async (pageList) => {
    try {
      const inputFile = inputFiles[0]
      const { docBlobRef } = inputFile

      const newJobConfig = { ...actionConfig }

      const doc = pageList
        .filter((page) => !page.deleted)
        .map((page) => ({
          docBlobRef,
          pageNr: page.nr,
          rotation: page.rotation,
        }))
      newJobConfig.produce = {
        ...newJobConfig.produce,
        docs: [...newJobConfig.produce.docs, { pages: doc }],
      }
      const jobRes = await dispatch(
        startPdfJob(inputFiles, newJobConfig, teamsUserCredential)
      )
      if (jobRes && jobRes?.status === 'ok') {
        showMessage(t('successJobInProgress'), MessageType.Success)
      } else {
        showMessage(
          t('Cannot create job. Status: ') + jobRes.status,
          MessageType.Error
        )
      }
    } catch (error) {
      showMessage(t('errErrorInJobCreation') + error.message, MessageType.Error)
    }
  }

  function cancelEdit() {
    navigate(-1)
  }

  const inputFile = inputFiles[0]
  return (
    <div className="ct-organize-pages-layout">
      <EditPages
        inputFile={inputFile}
        inputFiles={inputFiles}
        thumbnails={thumbnails}
        onStartDocProcessing={startDocProcessing}
        onCancel={cancelEdit}
      />
    </div>
  )
}

export default withMessageBox(OrganizePdf)
