export const getConstraint1 = (t) => ({
  oldText: {
    presence: {
      allowEmpty: false,
      message: `${t('error_emptyText')}`,
    },
  },
  newText: {
    presence: {
      allowEmpty: false,
      message: `${t('error_emptyText')}`,
    },
  },
})
