import { excludeMargin } from '../../../../utils/enums'
import {
  getMargin,
  getPageNumberFormat,
  getPositionKey,
} from '../../../../utils/toolHelper'

export const constraint1 = (t) => {
  const pageNumberFormat = getPageNumberFormat(t)
  const margin = getMargin(t)

  return {
    position: {
      presence: {
        allowEmpty: false,
        message: `{fieldName} ${t('error_emptyText')}`,
      },
    },
    marginX: function (value, attributes, attributeName, options, constraints) {
      const showHorizontalMargin = !excludeMargin.includes(
        getPositionKey(
          attributes?.position?.AlignX,
          attributes?.position?.AlignY
        )
      )
      if (attributes.margin === margin[3].value) {
        if (showHorizontalMargin)
          return {
            numericality: {
              greaterThanOrEqualTo: 0,
              lessThanOrEqualTo: 70,
              message: `^{fieldName} ${t('error_customText')}`,
            },
            presence: {
              allowEmpty: false,
              message: `^{fieldName} ${t('error_emptyText')}`,
            },
          }
      }
      return null
    },
    marginY: function (value, attributes, attributeName, options, constraints) {
      if (attributes.margin === margin[3].value)
        return {
          numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 70,
            message: `^{fieldName} ${t('error_customText')}`,
          },
          presence: {
            allowEmpty: false,
            message: `^{fieldName} ${t('error_emptyText')}`,
          },
        }
      return null
    },
    customText: function (
      value,
      attributes,
      attributeName,
      options,
      constraints
    ) {
      if (attributes?.pageNumberFormat === pageNumberFormat[3].value)
        return {
          presence: {
            allowEmpty: false,
            message: `^{fieldName} ${t('error_emptyText')}`,
          },
        }
      return null
    },
    fontColor: {
      presence: {
        allowEmpty: false,
        message: `^{fieldName} ${t('error_emptyText')}`,
      },
    },
  }
}
