export const ToolName = {
  EXTRACT_RESOURCES: 'extractResources',
  PDF_TO_EPUB: 'pdfToEpub',
  PDF_TO_EXCEL: 'pdfToExcel',
  PDF_TO_IMAGE: 'pdfToImage',
  PDF_TO_POWERPOINT: 'pdfToPowerpoint',
  PDF_TO_WORD: 'pdfToWord',
  EXCEL_TO_PDF: 'excelToPdf',
  IMAGE_TO_PDF: 'imageToPdf',
  PDF_CONVERTER: 'pdfConverter',
  POWERPOINT_TO_PDF: 'powerpointToPdf',
  WORD_TO_PDF: 'wordToPdf',
  ADD_BARCODE: 'addBarcode',
  ADD_WATERMARK: 'addWatermark',
  MERGE_PDF: 'mergePdf',
  ORGANIZE_PAGES: 'organizePages',
  PDF_EDITOR: 'pdfEditor',
  PDF_METADATA: 'pdfMetadata',
  SIGN_PDF: 'signPdf',
  FLATTEN_PDF: 'flattenPdf',
  REDACT_PDF: 'redactPdf',
  MERGE_PDF_OVERLAY: 'mergePdfOverlay',
  SPLIT_PDF: 'splitPdf',
  COMPRESS_PDF: 'compressPdf',
  OPTIMIZE_FOR_WEB: 'optimizeForWeb',
  PDF_OCR: 'pdfOcr',
  PREPARE_FOR_PRINT: 'prepareForPrint',
  REPAIR_PDF: 'repairPdf',
  PROTECT_PDF: 'protectPdf',
  UNLOCK_PDF: 'unlockPdf',
  JOB_HISTORY: 'jobHistory',
  ADD_PAGE_NUMBER: 'addPageNumber',
  'PDF/A': 'pdfa',
  FIND_AND_REPLACE: 'findAndReplace',
}

export const PopupScreen = {
  Action: 'continue',
  StartJob: 'startJob',
}

export const MessageType = {
  Success: 'Success',
  Error: 'Error',
}

export const RoutePath = {
  Home: '/home',
  Document: '/document',
  Viewer: '/viewer/',
  SignViewer: '/sign-viewer/',
  JobHistory: '/job-history',
  RedactEdit: '/redact-edit',
}

export const HeaderActiveLink = {
  [RoutePath.Home]: 'Home',
  [RoutePath.Document]: 'Document',
  [RoutePath.Viewer]: 'Edit PDF',
  [RoutePath.SignViewer]: 'Sign PDF',
  [RoutePath.JobHistory]: 'Job History',
  [RoutePath.RedactEdit]: 'Redact Edit',
}
export const PopupCat = {
  RECENT: 'lblRecent',
  MY_COMPUTER: 'lblMyComputer',
}

export const LoadingStates = {
  IDLE: 'idle',
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
}

export const locales = [
  'en', // only english lang support
  'de',
  'es',
  'hi',
  'id',
  'pt',
  'ta',
  'tr',
  'zh-hans',
  'fr',
  'it',
]

export const excludeMargin = ['0-1', '2-1']
