import React, { useEffect, useMemo, useState } from 'react'
import { validate } from 'validate.js'

import PagePicker from '../../../PagePicker'
import FormSection from '../../../../controls/FormSection'
import FormRow from '../../../../controls/FormRow'
import allPagesIcon from '../../../../controls/icons/allPages'
import firstPageIcon from '../../../../controls/icons/firstPage'
import selectPagesIcon from '../../../../controls/icons/selectPages'
import ButtonGroup from '../../../../controls/ButtonGroup'
import InputFormRow from '../../../../controls/InputFormRow'

import { useJobConfig } from '../../../JobConfig/JobContext'
import { useTranslation } from '../../../../i18n/useTranslation'
import { getConstraint1 } from './schema'

const FindAndReplaceConfig = ({ onChange, inputFiles, form }) => {
  const { updateActionConfig, setIsValid } = useJobConfig()
  const t = useTranslation('tool_findAndReplace')
  const [errors, setErrors] = useState()
  const filesReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )
  const [customSelection, setCustomSelection] = useState([])
  const [pagePickerOpen, setPagePickerOpen] = useState(false)
  useEffect(() => {
    const initialState = () => {
      const values = {
        pageSelectionType: 'all',
        newText: '',
        newText: '',
      }
      setIsValid(false)
      updateActionConfig(values)
    }
    initialState()
  }, [])

  const checkValid = (error) => {
    if (error) {
      setIsValid(false)
      return false
    }
    setIsValid(true)
    return true
  }

  const formValidate = (form) => {
    const error = validate(form, getConstraint1(t))
    setErrors(error || null)
    const isValid = checkValid(error)
    return isValid
  }

  const handleFieldChange = (event) => onChange(event, formValidate)
  const bindInput = (name) => {
    return {
      name,
      onChange: handleFieldChange,
      value: form[name],
    }
  }
  console.log('errors', errors)
  return (
    <>
      <FormSection>
        <FormRow
          label={t('lblPageSequence')}
          hint={t(`pagesDesc_${form.pageSelectionType}`)}
          errorMessage={errors?.pageSelectionType}
        >
          <ButtonGroup
            name="pageSelectionType"
            items={[
              {
                id: 'btnAllPages',
                value: 'all',
                label: t('allPages'),
                icon: allPagesIcon,
              },
              {
                id: 'btnFirstPage',
                value: 'first',
                label: t('firstPage'),
                icon: firstPageIcon,
              },
              {
                id: 'btnSelectPages',
                value: 'custom',
                label: t('customSelection'),
                icon: selectPagesIcon,
                disabled: !filesReady,
              },
            ]}
            value={form.pageSelectionType}
            type="radio"
            onClick={(e) => {
              if (e.target.value === 'custom') {
                setPagePickerOpen(true)
              }
            }}
            onChange={handleFieldChange}
          />
        </FormRow>

        <InputFormRow label={`Old Text:`} errorMessage={errors?.oldText}>
          <input
            type="text"
            name="oldText"
            value={form.oldText}
            disabled={!filesReady}
            onChange={handleFieldChange}
          />
        </InputFormRow>
        <InputFormRow label={`New Text:`} errorMessage={errors?.newText}>
          <input
            type="text"
            name="newText"
            value={form.newText}
            disabled={!filesReady}
            onChange={handleFieldChange}
          />
        </InputFormRow>
      </FormSection>
      {pagePickerOpen && (
        <PagePicker
          selectedPages={form.customSelection || []}
          onSelect={(selection) => {
            setCustomSelection(selection.selectedPages)
            updateActionConfig({ customSelection: selection.selectedPages })
            setPagePickerOpen(false)
          }}
          inputFiles={inputFiles}
          onCancel={() => {
            setPagePickerOpen(false)
          }}
        />
      )}
    </>
  )
}

export default FindAndReplaceConfig
