import React from 'react'
import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import compressIcon from '../../../../controls/icons/compress'
import compressMaxIcon from '../../../../controls/icons/compressMax'
import { useTranslation } from '../../../../i18n/useTranslation'

const CompressPdfConfig = ({ form, onChange }) => {
  const t = useTranslation('tool_compressPdf')
  return (
    <FormRow label={t('lblOptimize')} hint={t(`optimizeDesc_${form.profile}`)}>
      <ButtonGroup
        name="profile"
        items={[
          {
            id: 'btnOptimizeDefault',
            value: 'default',
            label: 'Default',
            icon: compressIcon,
          },
          {
            id: 'btnOptimizeMax',
            value: 'max',
            label: 'Max',
            icon: compressMaxIcon,
          },
        ]}
        type="button"
        value={form.profile}
        onChange={onChange}
      />
    </FormRow>
  )
}

export default CompressPdfConfig
