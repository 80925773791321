import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import InputFormRow from '../InputFormRow'

import Button from '../Button'
import fontSize from '../icons/fontSize'
import { colors, device, layout, media, spacing } from '../../theme'
import { useTranslation } from '../../i18n/useTranslation'
const Slider = styled.input.attrs({ type: 'range' })`
  width: 100%;
  margin: 10px 0;
`
const Wrapper = styled.div`
  position: relative;
`
const SliderWrapper = styled.div`
  position: absolute;
  width: 250px;
  bottom: 0;
  left: 0;
  background: ${colors.grayLighter};
  padding: ${spacing(1)};
  box-shadow: ${layout.elevate(5)};
  ${media.down(device.tablet)`
    width: 231px`};
`
const FontSizeTool = styled.div`
  display: flex;
`
const TextFont = styled(Button)`
  ${({ $active }) =>
    $active &&
    `
     box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    background: #f5f5fa;
    `}
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  // margin: -10px;
  align-items: center;
  margin: 16px 0;
  column-gap: 5px;
  width: 100%;
  label {
    font-size: ${fontSize.sm};
    margin-right: ${spacing(2)};
    width: 100%;
  }
`
const Col = styled.div`
  flex: 1 0 33.33%;
  // padding: 10px;
  min-width: ${(props) => props.$minWidth}px;
`
const CustomFormRow = styled(InputFormRow)`
  width: 70px;
  div {
    input {
      width: 100%;
    }
  }
`

const SliderFontSize = ({ value, filesReady, onClose, onChange, min, max }) => {
  const pickerRef = useRef(null)
  const t = useTranslation()

  const handleSliderChange = (event) => {
    onChange(event.target.value)
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        onClose(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <SliderWrapper ref={pickerRef}>
      <FontSizeTool>
        <Row>
          <label>{t('fontSize')}:</label>
          <Col $minWidth={150}>
            <Slider
              min={min}
              max={max}
              value={value}
              onChange={handleSliderChange}
            />
          </Col>
          <Col $minWidth={70}>
            <CustomFormRow>
              <input
                type="number"
                name="fontSize"
                min={min}
                max={max}
                value={value}
                disabled={!filesReady}
                onChange={(event) => onChange(event.target.value)}
              />
            </CustomFormRow>
          </Col>
        </Row>
      </FontSizeTool>
    </SliderWrapper>
  )
}

export default SliderFontSize
