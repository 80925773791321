import styled from 'styled-components'

import Icon from '../../../../controls/Icon'
import caretDownIcon from '../../../../controls/icons/caretDown'
import { colors, device, media } from '../../../../theme'

const Btn = styled.button`
  position: relative;
  // margin: 0px 5px;
  width: 100%;
  padding: 10px 5px 5px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);

  span {
    display: block;
    color: ${colors.grayDark};
  }
  &.pressed {
    background-color: ${colors.grayLight};
  }
  .icon {
    width: 12px;
    height: 12px;
    svg {
      width: 12px;
      height: 12px;
      fill: ${colors.gray};
    }
  }
  // ${media.down(device.tablet)`
  //   // width: 35px;
  //   display: flex;
  //   justify-content: space;
  // `}
  ${media.down(device.mobile)`
    // width: 25px;
  `}
`

export default ({
  disabled,
  hideOnMobile,
  onClick,
  children,
  id,
  toolTip,
  pressed,
}) => {
  const classNames = []
  if (disabled) {
    classNames.push('disabled')
  }
  if (hideOnMobile) {
    classNames.push('sm-hide')
  }
  if (pressed) {
    classNames.push('pressed')
  }

  return (
    <Btn
      id={id}
      type="button"
      className={classNames.join(' ')}
      onClick={() => {
        if (onClick && !disabled) {
          onClick()
        }
      }}
      title={toolTip}
    >
      <span>{children}</span>
      <Icon icon={caretDownIcon} />
    </Btn>
  )
}
