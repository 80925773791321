export const getConstraint1 = (t) => ({
  password: {
    presence: true,
    length: {
      minimum: 6,
      message: t('errMinPasswordLength'),
    },
  },
  confirmPassword: {
    equality: {
      attribute: 'password',
      message: `^${t('errPasswordNotMatch')}`,
      comparator: function (v1, v2) {
        return JSON.stringify(v1) === JSON.stringify(v2)
      },
    },
  },
})
