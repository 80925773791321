import React, { useEffect, useMemo, useState } from 'react'
import { validate } from 'validate.js'

import FormSection from '../../../../controls/FormSection'
import PasswordStrengthRange from '../../../../controls/PasswordStrengthRange'

import { useJobConfig } from '../../../JobConfig/JobContext'
import { useTranslation } from '../../../../i18n/useTranslation'
import { getConstraint1 } from './schema'
import InputField from '../../../../controls/InputField'

const ProtectPdfConfig = ({ onChange, inputFiles, form }) => {
  const { updateActionConfig, setIsValid } = useJobConfig()
  const t = useTranslation('tool_protectPdf')
  const [errors, setErrors] = useState()
  const filesReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )
  useEffect(() => {
    const initialState = () => {
      const values = {
        password: '',
        confirmPassword: '',
      }
      setIsValid(false)
      updateActionConfig(values)
    }
    initialState()
  }, [])

  const checkValid = (error) => {
    if (error) {
      setIsValid(false)
      return false
    }
    setIsValid(true)
    return true
  }

  const formValidate = (form) => {
    const error = validate(form, getConstraint1(t))
    setErrors(error || null)
    const isValid = checkValid(error)
    return isValid
  }

  const handleFieldChange = (event) => onChange(event, formValidate)
  const bindInput = (name) => {
    return {
      name,
      onChange: handleFieldChange,
      value: form[name],
    }
  }
  console.log('errors', errors)
  return (
    <>
      <FormSection>
        <InputField
          label={t('lblPassword')}
          inputType="password"
          onChange={handleFieldChange}
          name="password"
          disabled={!filesReady}
          value={form?.password}
          errorMessage={errors?.password?.pop()}
          errorFormat
          wrap
        />
        <PasswordStrengthRange password={form?.password} />

        <InputField
          label={t('lblConfirmPassword')}
          inputType="password"
          onChange={handleFieldChange}
          name="confirmPassword"
          value={form?.confirmPassword}
          errorMessage={errors?.confirmPassword?.pop()}
          disabled={!filesReady}
          wrap
        />
      </FormSection>
    </>
  )
}

export default ProtectPdfConfig
