import React from 'react'

export default function (props) {
  const { className } = props

  return (
    <div className={className ? className : ''}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="26"
        fill="none"
        viewBox="0 0 28 26"
      >
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M1.572 20h.769v-1.179h.646c.029 0 .058 0 .086-.002.094-.003.183-.013.268-.03.132-.025.252-.066.36-.123.197-.102.348-.246.453-.43a1.26 1.26 0 0 0 .155-.686 1.271 1.271 0 0 0-.154-.593 1.07 1.07 0 0 0-.444-.435 1.459 1.459 0 0 0-.704-.158H1.572V20Zm1.657-2.931a.791.791 0 0 0-.37-.077h-.518v1.213h.522c.148 0 .27-.025.366-.076a.488.488 0 0 0 .217-.215.673.673 0 0 0 .072-.318.656.656 0 0 0-.073-.316.487.487 0 0 0-.216-.212ZM4.809 20H6.1c.369 0 .686-.073.951-.218a1.47 1.47 0 0 0 .613-.627c.143-.272.214-.598.214-.977 0-.377-.071-.702-.214-.973a1.464 1.464 0 0 0-.609-.623c-.264-.146-.58-.218-.945-.218h-1.3V20Zm2.182-2.48a.77.77 0 0 0-.35-.377 1.2 1.2 0 0 0-.563-.12h-.5v2.318h.5c.222-.001.41-.041.562-.12a.777.777 0 0 0 .351-.379c.08-.171.12-.393.12-.664 0-.268-.04-.488-.12-.659ZM20.7.27l7 6.313c.098.08.175.177.227.286a.744.744 0 0 1 .073.346v16.232a1.724 1.724 0 0 1-.588 1.273 2.121 2.121 0 0 1-1.412.53H10a2.121 2.121 0 0 1-1.412-.53A1.724 1.724 0 0 1 8 23.447V21h2v2.447h16V9.018h-6a2.121 2.121 0 0 1-1.413-.53A1.724 1.724 0 0 1 18 7.215V1.804h-8V15H8V1.804c.002-.478.213-.936.588-1.274A2.121 2.121 0 0 1 10 0h10a.986.986 0 0 1 .383.066.88.88 0 0 1 .317.205ZM8.447 16.365V20h.769v-1.502h1.479v-.634h-1.48v-.867h1.64v-.633H8.447Zm2.67 4.183 1.173-4.354h.651l-1.172 4.354h-.651ZM13.124 20h.824l.27-.83h1.312l.27.83h.823l-1.253-3.636h-.99L13.122 20Zm1.765-2.805h-.029l-.447 1.376h.923l-.447-1.376ZM20 2.165v5.05h5.6L20 2.165Z"
          clip-rule="evenodd"
        />
        <path
          fill="currentColor"
          fill-rule="evenodd"
          d="M1.2 14h15.6a1.2 1.2 0 0 1 1.2 1.2v5.6a1.2 1.2 0 0 1-1.2 1.2H1.2A1.2 1.2 0 0 1 0 20.8v-5.6A1.2 1.2 0 0 1 1.2 14Zm0 1a.2.2 0 0 0-.2.2v5.6c0 .11.09.2.2.2h15.6a.2.2 0 0 0 .2-.2v-5.6a.2.2 0 0 0-.2-.2H1.2Z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  )
}
