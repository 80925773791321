import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const PositionOptions = styled.div`
  width: 70px;
  border: 1px solid #adadb6;
`

const PositionRow = styled.div`
  height: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 0;
  background: none;
`

const PositionItem = styled(PositionRow)`
  position: relative;
  cursor: pointer;
  border-right: 1px dashed #adadb6;
  ${(pros) => (pros.$top ? 'border-top: 1px dashed #adadb6;' : '')}
  ${(pros) => (pros.$bottom ? 'border-bottom: 1px dashed #adadb6;' : '')}
  ${(pros) => (pros.$right ? 'border-right: 1px dashed #adadb6;' : '')}
  ${(pros) => (pros.$left ? 'border-left: 1px dashed #adadb6;' : '')}
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  .dot {
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${(props) =>
      props.$clicked
        ? 'block'
        : 'none'}; /* Show or hide based on click state */
  }
`

const Position = ({ onPosition, defaultPosition, errorMessage, label }) => {
  const [selectedCell, setSelectedCell] = useState(null)
  const getKey = (alignX, alignY) => {
    const map = {
      'left-top': '0-0',
      'center-top': '0-1',
      'right-top': '0-2',
      'left-middle': '1-0',
      'center-middle': '1-1',
      'right-middle': '1-2',
      'left-bottom': '2-0',
      'center-bottom': '2-1',
      'right-bottom': '2-2',
    }

    const key = `${alignX}-${alignY}`
    return map[key] || null
  }
  const getPosition = (key) => {
    switch (key) {
      case '0-0':
        return { AlignX: 'left', AlignY: 'top' }
      case '0-1':
        return { AlignX: 'center', AlignY: 'top' }
      case '0-2':
        return { AlignX: 'right', AlignY: 'top' }
      case '1-0':
        return { AlignX: 'left', AlignY: 'middle' }
      case '1-1':
        return { AlignX: 'center', AlignY: 'middle' }
      case '1-2':
        return { AlignX: 'right', AlignY: 'middle' }
      case '2-0':
        return { AlignX: 'left', AlignY: 'bottom' }
      case '2-1':
        return { AlignX: 'center', AlignY: 'bottom' }
      case '2-2':
        return { AlignX: 'right', AlignY: 'bottom' }

      default:
        return
    }
  }

  useEffect(() => {
    if (defaultPosition) {
      const cellKey = getKey(defaultPosition?.AlignX, defaultPosition?.AlignY)
      const rejectedRows = ['1-0', '1-1', '1-2']
      if (rejectedRows.includes(cellKey)) return
      setSelectedCell(cellKey)
      onPosition && onPosition(getPosition(cellKey))
    }
  }, [])

  const handleClick = (row, col) => {
    const cellKey = `${row}-${col}`
    const rejectedRows = ['1-0', '1-1', '1-2']
    if (rejectedRows.includes(cellKey)) return
    if (selectedCell === cellKey) return
    setSelectedCell(cellKey)
    onPosition && onPosition(getPosition(cellKey))
  }

  return (
    <>
      <label className="formRow__label">{label}</label>
      <PositionOptions>
        {[0, 1, 2].map((row) => (
          <PositionRow key={row}>
            {[0, 1, 2].map((col) => (
              <PositionItem
                key={col}
                $bottom
                $right
                onClick={() => handleClick(row, col)} // Handle click
                $clicked={selectedCell === `${row}-${col}`} // Pass clicked state
              >
                <div className="dot" />
              </PositionItem>
            ))}
          </PositionRow>
        ))}
      </PositionOptions>
      {errorMessage && (
        <div className="formRow__errorMessage">{errorMessage}</div>
      )}
    </>
  )
}

export default Position
