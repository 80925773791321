import React, { memo } from 'react'
import { useTranslation } from '../../i18n/useTranslation'
import PdfPasswordRequired from './PdfPasswordRequired'
import trashIcon from '../../controls/icons/trash'
import FileIcon from './FileIcon'
import { FileItem, Thumbnail } from './styles'
import {
  FileProps,
  File,
  FileName,
  FileDesc,
  Row,
  FileList,
  FileActions,
} from './components'
import ProgressBar from '../../controls/ProgressBar'
import Button from '../../controls/Button'

import { formatFileSize } from '../../utils/helper'

const RenderFile = memo((props) => {
  const t = useTranslation()
  const { file, onDeleteFile, onCancelUpload, i, isShowDelete } = props
  if (file.status === 'error') {
    switch (file.error) {
      // case 'UploadFailed':
      //   return <FileUploadError {...file} onDeleteFile={onDeleteFile} />
      case 'PasswordRequired':
        return (
          <PdfPasswordRequired
            {...file}
            isShowDelete={isShowDelete}
            onDeleteFile={onDeleteFile}
          />
        )
      default:
        return
    }
  }
  return (
    <FileItem>
      <File key={file?.docIdExt + i}>
        <Thumbnail>
          <FileIcon
            name={file?.fileName}
            thumbnail={file?.docMetadata?.thumbnail}
            inProgress={file?.status !== 'finished'}
          />
        </Thumbnail>
        <FileDesc>
          <FileName>{(file && file?.fileName) || ''}</FileName>
          {file?.status !== 'finished' && (
            <ProgressBar progress={file?.progress} />
          )}
          {file?.status === 'finished' && (
            <Row>
              <FileProps>
                <span>
                  {t('lblSize')}: {formatFileSize(file?.docMetadata?.size)}
                </span>
                <span>
                  {t('lblPageCount')}: {file?.docMetadata?.pageCount}
                </span>
              </FileProps>
              {isShowDelete && (
                <FileActions>
                  <Button
                    type="button"
                    size="small"
                    startIcon={trashIcon}
                    onClick={onDeleteFile}
                  >
                    {t('btnDelete')}
                  </Button>
                </FileActions>
              )}
            </Row>
          )}
        </FileDesc>
      </File>
    </FileItem>
  )
})
export default RenderFile
