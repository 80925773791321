import React, { useMemo, useState } from 'react'

import FormSection from '../../../../controls/FormSection'
import ToggleButton from '../../../../controls/ToolBarToggleButton'
import { useJobConfig } from '../../../JobConfig/JobContext'

import { useTranslation } from '../../../../i18n/useTranslation'

import { validate } from 'validate.js'
import { getConstraint1 } from './schema'
import {
  getMargin,
  getPageNumberFormat,
  getPositionKey,
} from '../../../../utils/toolHelper'
import { Col, Row } from '../../../../controls/Common'
import { CustomSelect } from './styles'
import { Compliance } from '../../../../utils/toolEnums'

const PDFAConfig = ({ onChange, inputFiles }) => {
  const { actionConfig, setIsValid } = useJobConfig()
  const t = useTranslation('tool_pdfA')
  const [errors, setErrors] = useState()
  const filesReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )

  const checkValid = (error) => {
    if (error) {
      setIsValid(false)
      return false
    }
    setIsValid(true)
    return true
  }

  const formValidate = (form) => {
    const error = validate(form, getConstraint1(t))
    setErrors(error || null)
    const isValid = checkValid(error)
    return isValid
  }

  const handleFieldChange = (event) => onChange(event, formValidate)
  const bindInput = (name) => {
    return {
      name,
      onChange: handleFieldChange,
      value: actionConfig[name],
    }
  }
  console.log('errors', errors)
  return (
    <>
      <FormSection>
        <Row>
          <Col>
            <ToggleButton
              checked={actionConfig.linearize}
              label={t('lblLinearize')}
              name="linearize"
              onChange={handleFieldChange}
            />
          </Col>
          <Col>
            <CustomSelect
              {...bindInput('compliance')}
              // {...bindError('compliance')}
              errorMessage={errors?.compliance}
              label={t('lblCompliance')}
              inputType="select"
              items={Compliance}
              disabled={!filesReady}
            />
          </Col>
        </Row>
      </FormSection>
    </>
  )
}

export default PDFAConfig
