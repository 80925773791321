import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BtnWrapper, FormWrapper, MainWrapper, ParseForm } from './styles'
import PdfDisplay from './components/PdfDisplay'
import ParseInfo from './components/ParseInfo'
import backArrow from '../../controls/icons/backArrow'
import { RoutePath } from '../../utils/enums'
import Button from '../../controls/Button'
import { useViewport } from '../../hooks/ViewportContext'
import { cleanEmptyNotification } from '../../store/notification/action'
import { useDispatch, useSelector } from 'react-redux'

const RedactEdit = () => {
  const location = useLocation()
  const { tool, url, docIdExt } = location.state
  const childRef = useRef()
  const navigate = useNavigate()
  const viewport = useViewport()
  const dispatch = useDispatch()
  const [configInfo, setConfigInfo] = useState()
  const [pageNum, setPageNum] = useState(1)
  const [pdfDocument, setPdfDocument] = useState()
  const [pageNumber, setPageNumber] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [testTemplateList, setTestTemplateList] = useState([])
  const [jobStarted, setJobStarted] = useState(false)
  const _inputFiles = useSelector((state) =>
    state.notification.find((e) => e?.docIdExt === docIdExt)
  )
  const inputFiles = Array.isArray(_inputFiles) ? _inputFiles : [_inputFiles]

  useEffect(() => {
    if (url) {
      const loadPdf = async () => {
        setIsLoading(true)
        let pdfLib
        pdfLib = await import('pdfjs-dist/build/pdf')
        const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry')
        pdfLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

        const pdf = await pdfLib.getDocument(url).promise
        setPdfDocument(null)
        setPageNumber(1)
        setPageNum(1)
        setPdfDocument(pdf)
        setIsLoading(false)
      }
      loadPdf()
    }
  }, [url])
  const handleGoBack = () => {
    // Navigate back
    dispatch(cleanEmptyNotification())
    navigate(RoutePath.Home)
  }
  const handleSubmit = () => {
    childRef.current.handleSubmit()
  }
  return (
    <MainWrapper $height={viewport.height}>
      <BtnWrapper>
        <Button
          type="button"
          // color="primary"
          startIcon={backArrow}
          onClick={handleGoBack}
        >
          Back
        </Button>
        <Button
          type="button"
          color="primary"
          loading={jobStarted}
          disabled={jobStarted}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </BtnWrapper>
      {/* <FormWrapper> */}
      <ParseForm $height={viewport.height} $width={viewport.height}>
        <ParseInfo
          configInfo={configInfo}
          setConfigInfo={setConfigInfo}
          testTemplateList={testTemplateList}
          pageNum={pageNum}
        />
        <PdfDisplay
          configInfo={configInfo}
          setConfigInfo={setConfigInfo}
          testTemplateList={testTemplateList}
          setPageNum={setPageNum}
          ref={childRef}
          jobStarted={jobStarted}
          setJobStarted={setJobStarted}
          pdfDocument={pdfDocument}
          setPageNumber={setPageNumber}
          pageNumber={pageNumber}
          toolName={tool?.toolName}
          inputFiles={inputFiles}
        />
      </ParseForm>
      {/* </FormWrapper> */}
    </MainWrapper>
  )
}

export default RedactEdit
