import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { SketchPicker } from 'react-color'

import FontColorIcon from '../../../../../controls/icons/fontColor'
import BoldBIcon from '../../../../../controls/icons/boldB'
import ItalicsIIcon from '../../../../../controls/icons/italicsI'
import FontSizeIcon from '../../../../../controls/icons/fontSize'
import Button from '../../../../../controls/Button'
import { device, media } from '../../../../../theme'
import SliderFontSize from '../../../../../controls/SliderNumber'
// import ToggleButton from './ToggleButton'
const ToolOptions = styled.div`
  position: relative;
  z-index: 22;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  margin-top: -8px;
`
const ColorBox = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${(prop) => prop.color};
  border: 1px solid black;
`
const FontColor = styled(Button)`
  color: ${(prop) => prop.$color};
`
const TextFont = styled(Button)`
  ${({ $active }) =>
    $active &&
    `
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    background: #f5f5fa;
    `}
`

const EditorOption = styled.div`
  display: flex;
  column-gap: 5px;
`

const SketchFontColor = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  ${media.down(device.mobile)`
  // right: 5px;
  `}
  .sketch-picker {
    > div:first-child {
      padding-bottom: 50% !important;
    }
    input {
      height: auto;
    }
  }
`
const ToolBar = ({ values, onChange, filesReady }) => {
  const [showColor, setShowColor] = useState(false)
  const [showSlider, setShowSlider] = useState(false)
  const pickerRef = useRef(null)

  // onblur closes color picker
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowColor(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const toggleFontColor = () => {
    if (!showColor) setShowColor(true)
  }
  const padLeftZero = (n, padLength) => {
    const z = '0'
    n = n + ''
    return n.length >= padLength
      ? n
      : new Array(padLength - n.length + 1).join(z) + n
  }
  const onFontColorChange = ({ hex, rgb }) => {
    // const { hex, rgb } = color
    const { a } = rgb
    const _hex =
      (hex === 'transparent' ? '#000000' : hex) +
      padLeftZero(parseInt(a * 255, 10).toString(16), 2)
    const e = {
      target: {
        name: 'fontColor',
        value: _hex,
        type: 'radio',
      },
    }
    onChange(e)
  }
  const handleTextStyle = (field, value) => {
    const e = {
      target: {
        name: field,
        value: value,
        type: 'radio',
      },
    }
    onChange(e)
  }

  return (
    <ToolOptions>
      <EditorOption>
        <FontColor
          variant="icon"
          startIcon={FontColorIcon}
          onClick={toggleFontColor}
          $color={values.fontColor}
        />
        <TextFont
          $active={values.isBold}
          variant="icon"
          startIcon={BoldBIcon}
          onClick={() => handleTextStyle('isBold', !values.isBold)}
        />
        <TextFont
          $active={values.isItalics}
          variant="icon"
          startIcon={ItalicsIIcon}
          onClick={() => handleTextStyle('isItalics', !values.isItalics)}
        />
        <TextFont
          // $active={values.fontSize}
          variant="icon"
          startIcon={FontSizeIcon}
          onClick={() => setShowSlider(true)}
        />
        {showSlider && (
          <SliderFontSize
            value={values.fontSize}
            filesReady={filesReady}
            onClose={setShowSlider}
            min={10}
            max={50}
            onChange={(value) => handleTextStyle('fontSize', value)}
          />
        )}

        {showColor && (
          <SketchFontColor ref={pickerRef}>
            <SketchPicker
              color={values.fontColor}
              onChangeComplete={onFontColorChange}
              width={230}
            />
          </SketchFontColor>
        )}
      </EditorOption>
    </ToolOptions>
  )
}

export default ToolBar
