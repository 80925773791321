import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { app } from '@microsoft/teams-js'

import { Type } from './mutation'
import { fileUpload } from '../../service/document/fileUpload'
import { executePdfJob } from '../../service/document/executePdfJob'
import { errorHandler } from '../error/action'
import { getDownloadUrl } from '../../service/document/getDownloadUrl'
import { startHub } from '../hub/hubMiddleware'
import { getOneDriveInfo } from '../../service/oneDrive/getDriveInfo'
import { addFileFromJobHistoryService } from '../../service/document/addFileFromJobHistory'
import { createId } from '../../utils/createId'
import { formatDocs, UpdateConfig } from '../../utils/notificationHelper'

export const createNotification = (data) => (dispatch) => {
  dispatch({ type: Type.NOTIFICATION_TRIGGER, data: data })
}

const saveFilesToOneDrive = (jobConfig, token, driveId, tenantId) => {
  const isSaveToOneDrive = jobConfig?.isSaveToOneDrive
  let saveToOneDrive
  if (isSaveToOneDrive)
    saveToOneDrive = {
      actionType: 'SaveToOneDrive',
      parameters: [
        {
          name: 'token',
          value: token,
        },
        {
          name: 'driveId',
          value: driveId,
        },
        {
          name: 'itemPath',
          value: jobConfig.customItemPath,
        },
        {
          name: 'tenantId',
          value: tenantId,
        },
      ],
    }
  const _jobConfig = { ...jobConfig }
  delete _jobConfig.customItemPath
  delete _jobConfig.isSaveToOneDrive
  return { saveToOneDrive, _jobConfig }
}

export const startPdfJob =
  (notifications, jobConfig, teamsUserCredential) =>
  async (dispatch, getState) => {
    const { hub } = getState()
    const { toolName } = notifications[0]
    const { connectionId } = hub
    const teamsContext = await app.getContext()
    var tokenRes = await teamsUserCredential.getToken('')

    const diveId = await getOneDriveInfo(
      tokenRes.token,
      teamsContext.user.tenant.id
    )

    const docs = notifications.reduce((accumulator, current) => {
      return [...accumulator, current.docs[0]]
    }, [])
    const inputFiles = docs.map((d) => {
      return {
        docIdExt: d.docIdExt,
        name: d.fileName,
        docBlobRef: d.docBlobRef,
        docMetadata: {
          size: d.docMetadata?.size ?? 0,
          pageCount: d.docMetadata?.pageCount ?? 0,
        },
      }
    })

    const { _jobConfig, saveToOneDrive } = saveFilesToOneDrive(
      jobConfig,
      tokenRes.token,
      diveId,
      teamsContext.user.tenant.id
    )

    const updatedConfig = UpdateConfig(toolName, _jobConfig)
    if (saveToOneDrive) updatedConfig.push(saveToOneDrive)
    const token =
      'MjBjOGE2YjctOTVmZi00OTdjLTk5YTMtNjcyMzUxOTlmZGM3OldJa2Z2WkgyWXV2WlhnSUwzU01Lb089S1EmSHlIZDVo'

    try {
      const jobRes = await executePdfJob(
        createId(),
        toolName,
        inputFiles,
        updatedConfig,
        connectionId,
        toolName,
        token
      )
      const _docs = docs.map((e) => ({
        docIdExt: e.docIdExt,
      }))

      dispatch({ type: Type.CLEAR_JOB, data: _docs })
      return jobRes
    } catch (error) {
      dispatch(errorHandler(error))
      return false
    }
  }

export const executeFileUpload = createAsyncThunk(
  'document/fileDownload',
  async (
    { tool, files, docIdExt },
    { dispatch, rejectWithValue, fulfillWithValue, getState }
  ) => {
    try {
      let connection
      const { hub } = getState()
      const { connectionId: hubConnectionId, status } = hub

      if (status != 'connected') {
        connection = await dispatch(startHub())
      }
      let connectionId = connection?.payload?.connectionId || hubConnectionId
      if (!connectionId) throw new Error('Signalr connection is not present')
      if (!files?.length) throw new Error('File is empty')
      const file = new Blob([files[0]], { type: files[0].type })
      file.name = files[0].name
      const docProcessing = { storageType: 'cdoc', toolName: tool.toolName }
      const thumbnail = 88
      const onprogress = (progress) => {
        dispatch({
          type: Type.INITIATE_FILE_UPLOAD,
          data: {
            docIdExt,
            progress,
            fileName: file.name,
            toolName: docProcessing.toolName,
            isNotification: false,
          },
        })
      }

      const data = await fileUpload(
        docProcessing.storageType,
        file,
        docIdExt,
        docProcessing.toolName,
        connectionId,
        onprogress,
        thumbnail
      )
      const docs = formatDocs(data, docIdExt, file)
      dispatch({
        type: Type.INITIATE_FILE_UPLOAD,
        data: { docIdExt, docs, ...docs[0] },
      })
      return data
    } catch (error) {
      dispatch(errorHandler(error))
      rejectWithValue(error.message)
    }
  }
)

export const downloadFile = createAsyncThunk(
  'document/fileDownload',
  async ({ name, docBlobRef, storageType }, { dispatch, rejectWithValue }) => {
    try {
      const file = {
        name: name,
        docBlobRef: docBlobRef,
        storageType: storageType,
      }
      const data = await getDownloadUrl([file])
      return data
    } catch (error) {
      dispatch(errorHandler(error))
      return rejectWithValue(error.message)
    }
  }
)

export const cleanEmptyNotification = () => (dispatch) =>
  dispatch({ type: Type.RESET_NOTIFICATION, data: {} })
export const deleteNotification = (docIdExt) => (dispatch) =>
  dispatch({
    type: Type.DELETE_NOTIFICATION,
    data: {
      docIdExt,
    },
  })
export const oneDriveNotification =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: Type.ONE_DRIVE_NOTIFICATION,
      data,
    })

export const addFileFromJobHistory = createAsyncThunk(
  'document/addFileFromHistory',
  async ({ file }, { dispatch, getState, rejectWithValue }) => {
    try {
      let connection

      // const canAddFileRes = canAddFile(file, getState)

      // if (canAddFileRes !== 'ok') {
      //   return returnAddFileRejected(canAddFileRes)
      // }
      const { hub } = getState()
      const { connectionId: hubConnectionId, status } = hub
      if (status != 'connected') {
        connection = await dispatch(startHub())
      }
      let connectionId = connection?.payload?.connectionId || hubConnectionId
      // connectHub(dispatch, hub)

      // dispatch(mutations.uploadFileStarted(file))
      // dispatch(actions.updateFileDropStatus())
      const res = await addFileFromJobHistoryService({
        ...file,
        fileId: createId(),
        connectionId,
      })

      const pageCount =
        res.docMetadata && typeof res.docMetadata.numberOfPages === 'number'
          ? res.docMetadata.numberOfPages
          : 0

      // if (
      //   !user.paid &&
      //   pageCount > allowedPageLimit &&
      //   limitedFeatures.includes(docProcessing.toolName)
      // ) {
      //   dispatch(mutations.filePageCountExceeeded())
      // } else {
      const docIdExt = createId()

      const data = { ...res }
      data.status = 'finished'
      data.errDetails = ''
      if (
        res.status === 'error' &&
        !(res.error === 'PasswordRequired' && file.jobType === 'unlockPdf')
      ) {
        data.status = 'error'
        data.errDetails = res.error
      }
      data.docBlobRef = res.uri
      data.docMetadata = res.docMetadata
      data.pageCount = pageCount

      const docs = formatDocs([data], docIdExt, file)
      dispatch({
        type: Type.INITIATE_FILE_UPLOAD,
        data: { docIdExt, docs, ...docs[0] },
      })
      dispatch({
        type: Type.INITIATE_FILE_UPLOAD,
        data: {
          docIdExt,
          progress: 1,
          fileName: file.name,
          toolName: file.jobType,
          isNotification: false,
        },
      })
    } catch (error) {
      dispatch(errorHandler(error))
      return rejectWithValue(error.message)
    }
  }
)
