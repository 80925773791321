import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import Button from '../../../controls/Button'
// import docProcessingActions from '../../../store/docProcessing/actions'
import hubActions from '../../../store/hub/actions'

import { layout, media } from '../../../theme'

import Pagination from '../../../controls/Pagination'
import ResultTable from '../../../controls/ResultTable'
import { useSelector, useDispatch } from 'react-redux'

import { getEmptyKey } from './ParseInfo'
import GrabBox from './GrabBox'
import { useJobConfig } from '../../../components/JobConfig/JobContext'
import { startPdfJob } from '../../../store/notification/action'
import { getJobConfig } from '../../../utils/helper'
import { TeamsFxContext } from '../../../components/TeamFxContextProvider'
import { RoutePath } from '../../../utils/enums'
import { useNavigate } from 'react-router-dom'

const ParseDocumentCanvas = styled.canvas`
  direction: ltr;
  padding: ${layout.gap};
  background-color: lightgrey;
`

const CanvasLayer = styled.div`
  position: absolute;
  cursor: crosshair;
  margin: ${layout.gap};
`

const CanvasWrapper = styled.div`
  display: flex;
  position: relative;
`
const PdfDisplayScrollX = styled.div`
  position: relative;
`

export default forwardRef(
  (
    {
      configInfo,
      setConfigInfo,
      setPageNum,
      setJobStarted,
      pdfDocument,
      pageNumber,
      setPageNumber,
      toolName,
      inputFiles,
    },
    ref
  ) => {
    const mismatchPpiFix = 71 / 72
    const refCanvasLayer = useRef()
    const refParseDocumentCanvas = useRef()
    const navigate = useNavigate()
    const [mode, setMode] = useState('')
    const [pdfLoaded, setPdfLoaded] = useState(false)
    const [parseResultList, setParseResultList] = useState([])
    // const { jobConfig } = useJobConfig()
    const [grabBoxList, setGrabBoxList] = useState([])
    const { teamsUserCredential } = useContext(TeamsFxContext)

    const dispatch = useDispatch()
    const renderTaskRef = useRef(null)

    function hexToRgb(hex) {
      hex = hex.replace('#', '').trim()
      var r = parseInt(hex.substring(0, 2), 16) / 255
      var g = parseInt(hex.substring(2, 4), 16) / 255
      var b = parseInt(hex.substring(4, 6), 16) / 255
      return {
        r: r,
        g: g,
        b: b,
      }
    }

    const handleSubmit = async () => {
      setJobStarted(true)
      dispatch(hubActions.startHub())
      // [
      //   {
      //     coordinates: { x1: 100, x2: 200, y1: 100, y2: 200 },
      //     color: { red: 1, green: 0, blue: 0 },
      //     fillColor: { red: 0, green: 0, blue: 0 },
      //     borderColor: { red: 1, green: 0, blue: 0 },
      //     overlayText: 'Red1',
      //     overlayTextAlignment: 'center',
      //     page: 1,
      //     repeatText: true,
      //   },
      // ]

      localStorage.removeItem('docProcessing')
      const redactBoxes = (configInfo?.keys || []).map((e) => ({
        coordinates: e.position,
        fillColor: hexToRgb(e.fillColor),
        page: e.pages,
      }))
      const redactPdf = {
        redactBoxes: JSON.stringify(redactBoxes),
      }
      const jobConfig = getJobConfig(toolName)
      const newJobConfig = { ...jobConfig, redactPdf }
      await dispatch(startPdfJob(inputFiles, newJobConfig, teamsUserCredential))
      setJobStarted(false)
        navigate(RoutePath.Home)
      // dispatch(docProcessingActions.startProcessing())
    }
    useImperativeHandle(ref, () => ({
      handleSubmit,
    }))
    const renderPage = async (pageNum) => {
      const canvas = refParseDocumentCanvas.current
      if (pdfDocument && canvas) {
        const page = await pdfDocument.getPage(pageNum)

        let scale = 1.0
        const viewport = page.getViewport({ scale: scale })

        // Prepare canvas using PDF page dimensions

        if (canvas) {
          const context = canvas.getContext('2d')
          canvas.height = viewport.height
          canvas.width = viewport.width
          if (renderTaskRef.current) {
            renderTaskRef.current.cancel()
          }
          // Render PDF page into canvas context
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          }
          const renderTask = page.render(renderContext)
          renderTaskRef.current = renderTask
          try {
            await renderTask.promise
          } catch (error) {
            if (error.name !== 'RenderingCancelledException') {
              console.error('Render error:', error)
            }
          }
        }
      }
    }

    const resizeCanvasLayer = () => {
      const theCanvas = refParseDocumentCanvas.current
      const canvasLayer = refCanvasLayer.current
      if (theCanvas && canvasLayer) {
        canvasLayer.style.width = theCanvas.getAttribute('width') + 'px'
        canvasLayer.style.height = theCanvas.getAttribute('height') + 'px'
      }
    }

    const addKey = (key) => {
      let _configInfo = { ...configInfo }
      let i = 1
      if (!_configInfo.keys) {
        _configInfo.keys = []
      }
      while (_configInfo.keys.find((k) => k.keyname === key.keyname)) {
        key.keyname = 'BoxName_' + i
        i++
      }
      _configInfo.keys.push(key)
      setConfigInfo(_configInfo)
    }

    const updateKey = (boxIndex, key) => {
      let _configInfo = { ...configInfo }
      _configInfo.keys[boxIndex] = key
      setConfigInfo(_configInfo)
    }

    const move = (x, y, e, type) => {
      const theCanvas = refCanvasLayer.current
      if (theCanvas) {
        const theCanvasRectangle = theCanvas.getBoundingClientRect()

        if (mode === 'create') {
          if (configInfo?.keys?.length > 0) {
            const boxIndex = configInfo.keys.length - 1
            let _key = { ...configInfo.keys[boxIndex] }
            _key.position.x2 = Math.round(
              (x - theCanvasRectangle.left) / mismatchPpiFix
            )
            _key.position.y1 = Math.round(
              (theCanvasRectangle.height + theCanvasRectangle.top - y) /
                mismatchPpiFix
            )

            updateKey(boxIndex, _key)
          }
        }

        if (mode.indexOf('move') > -1) {
          const boxIndex = parseInt(mode.replace('move-box_', ''))
          const boxBoundRect = e.target.getBoundingClientRect()
          let _key = { ...configInfo.keys[boxIndex] }

          let oldLeft = _key.position.x1
          let oldTop =
            theCanvasRectangle.top +
            theCanvasRectangle.height -
            _key.position.y2
          //add changes here
          const boxWidth =
            (_key.position.x2 - _key.position.x1) * mismatchPpiFix
          const boxHeight =
            (_key.position.y2 - _key.position.y1) * mismatchPpiFix
          // const boxWidth = boxBoundRect.width
          // const boxHeight = boxBoundRect.height
          let newLeft = x - theCanvasRectangle.left - boxWidth / 2
          let newTop = y - theCanvasRectangle.top - boxHeight / 2

          newLeft = Math.min(
            theCanvasRectangle.width - boxWidth,
            Math.max(newLeft, 0)
          )

          newTop = Math.min(
            theCanvasRectangle.height - boxHeight,
            Math.max(newTop, 0)
          )

          _key.position.x1 = Math.round(newLeft / mismatchPpiFix)
          _key.position.x2 = Math.round((newLeft + boxWidth) / mismatchPpiFix)
          _key.position.y1 = Math.round(
            (theCanvasRectangle.height - (newTop + boxHeight)) / mismatchPpiFix
          )
          _key.position.y2 = Math.round(
            (theCanvasRectangle.height - newTop) / mismatchPpiFix
          )

          updateKey(boxIndex, _key)
        }

        if (mode.indexOf('resize') > -1) {
          const splitText = mode.split('-')
          const resizeType = splitText[1]
          const boxIndex = parseInt(splitText[2].replace('box_', ''))

          let _key = { ...configInfo.keys[boxIndex] }

          if (_key && _key.position) {
            if (resizeType === 'resizeBottomRight') {
              _key.position.x2 = Math.round(
                (x - theCanvasRectangle.left) / mismatchPpiFix
              )
              _key.position.y1 = Math.round(
                (theCanvasRectangle.top + theCanvasRectangle.height - y) /
                  mismatchPpiFix
              )
            } else if (resizeType === 'resizeTopLeft') {
              _key.position.x1 = Math.round(
                (x - theCanvasRectangle.left) / mismatchPpiFix
              )
              _key.position.y2 = Math.round(
                (theCanvasRectangle.top + theCanvasRectangle.height - y) /
                  mismatchPpiFix
              )
            } else if (resizeType === 'resizeTopRight') {
              _key.position.x2 = Math.round(
                (x - theCanvasRectangle.left) / mismatchPpiFix
              )
              _key.position.y2 = Math.round(
                (theCanvasRectangle.top + theCanvasRectangle.height - y) /
                  mismatchPpiFix
              )
            } else if (resizeType === 'resizeBottomLeft') {
              _key.position.x1 = Math.round(
                (x - theCanvasRectangle.left) / mismatchPpiFix
              )
              _key.position.y1 = Math.round(
                (theCanvasRectangle.top + theCanvasRectangle.height - y) /
                  mismatchPpiFix
              )
            } else if (resizeType === 'resizeLeft') {
              _key.position.x1 = Math.round(
                (x - theCanvasRectangle.left) / mismatchPpiFix
              )
            } else if (resizeType === 'resizeBottom') {
              _key.position.y1 = Math.round(
                (theCanvasRectangle.top + theCanvasRectangle.height - y) /
                  mismatchPpiFix
              )
            } else if (resizeType === 'resizeRight') {
              _key.position.x2 = Math.round(
                (x - theCanvasRectangle.left) / mismatchPpiFix
              )
            } else if (resizeType === 'resizeTop') {
              _key.position.x2 = Math.round(
                (x - theCanvasRectangle.left) / mismatchPpiFix
              )
            }

            updateKey(boxIndex, _key)
          }
        }
      }
    }

    const onMouseMove = (e /* : MouseEvent */) => {
      const { clientX, clientY, pageX, pageY, screenX, screenY } = e
      move(clientX, clientY, e)
      if (e.buttons === 0) {
        setMode('')
      }
    }

    const onTouchMove = (e /*: TouchEvent */) => {
      const { clientX, clientY } = e.touches[0]
      move(clientX, clientY, e, 'touch')
      if (e.touches.length === 0) {
        setMode('')
      }
    }

    const onMouseDown = (e /* :MouseEvent */) => {
      if (e.button === 0) {
        const { clientX, clientY, pageX, pageY, screenX, screenY } = e
        const rectangle = e.target.getBoundingClientRect()

        const elementId = e.target.id
        if (e.target === refCanvasLayer.current) {
          const theCanvas = refParseDocumentCanvas.current
          if (theCanvas) {
            //const canvasWidth = parseInt(theCanvas.getAttribute('width'))
            const canvasHeight = parseInt(theCanvas.getAttribute('height'))

            let key = getEmptyKey(pageNumber)

            key.position.x1 = Math.round(
              clientX - rectangle.left / mismatchPpiFix
            )
            key.position.x2 = Math.round(
              clientX - rectangle.left / mismatchPpiFix
            )
            key.position.y1 = Math.round(
              (canvasHeight + rectangle.top - clientY) / mismatchPpiFix
            )
            key.position.y2 = Math.round(
              (canvasHeight + rectangle.top - clientY) / mismatchPpiFix
            )

            addKey(key)
            setMode('create')
          }
        } else if (elementId.indexOf('box_') == 0) {
          setMode(`move-${elementId}`)
        } else if (elementId.indexOf('resize') == 0) {
          const boxId = e.target.parentElement.id
          setMode(`resize-${elementId}-${boxId}`)
        }
      }
    }

    const onTouchStart = (e /* : TouchEvent */) => {
      setMode('create')
    }

    const onDragTargetStart = (e) => {
      if (e) {
        e.preventDefault()
      }
    }

    useEffect(() => {
      window.addEventListener('resize', resizeCanvasLayer)

      return () => {
        window.removeEventListener('resize', resizeCanvasLayer)
      }
    }, [])

    useEffect(() => {
      const _render = async () => {
        await renderPage(pageNumber)
        resizeCanvasLayer()
        setPdfLoaded(true)
      }
      const theCanvas = refParseDocumentCanvas.current
      const canvasLayer = refCanvasLayer.current
      if (pageNumber && pdfDocument && theCanvas && canvasLayer) {
        _render()
      }
      return () => {
        if (renderTaskRef.current) {
          renderTaskRef.current.cancel()
        }
      }
    }, [
      pageNumber,
      pdfDocument,
      refParseDocumentCanvas.current,
      refCanvasLayer.current,
    ])

    const handlePageNumber = (n) => {
      setPageNumber(n)
      setPageNum(n)
    }
    const grabBoxFun = useCallback(() => {
      if (configInfo && pdfDocument && pageNumber && pdfLoaded) {
        const theCanvas = refParseDocumentCanvas.current

        if (theCanvas) {
          const canvasWidth = parseInt(theCanvas.getAttribute('width'))
          const canvasHeight = parseInt(theCanvas.getAttribute('height'))

          const newGrabBoxList = [] // Use a new array to avoid mutation
          if (configInfo.keys) {
            configInfo.keys.forEach((k) => {
              const { pages, position, searchWholePage } = k
              const isCurrentPage =
                pages === 'all' ||
                (pages === 'first' && pageNumber === 1) ||
                (pages === 'last' && pageNumber === pdfDocument.numPages) ||
                pages == pageNumber

              if (isCurrentPage && !searchWholePage && position) {
                const { x1, y1, x2, y2 } = position

                if (
                  (x1 && x1 !== 0) ||
                  (y1 && y1 !== 0) ||
                  (x2 && x2 !== 0) ||
                  (y2 && y2 !== 0)
                ) {
                  newGrabBoxList.push({
                    k,
                    displayGrabBox: true,
                    left: Math.min(
                      canvasWidth,
                      Math.round(x1 * mismatchPpiFix)
                    ),
                    top: Math.max(
                      0,
                      canvasHeight - Math.round(y2 * mismatchPpiFix)
                    ),
                    firstX: Math.min(
                      canvasWidth,
                      Math.round(x1 * mismatchPpiFix)
                    ),
                    firstY: Math.max(
                      0,
                      canvasHeight - Math.round(y2 * mismatchPpiFix)
                    ),
                    secondX: Math.min(
                      canvasWidth,
                      Math.round(x2 * mismatchPpiFix)
                    ),
                    secondY: Math.max(
                      0,
                      canvasHeight - Math.round(y1 * mismatchPpiFix)
                    ),
                  })
                }
              }
            })
          }
          setGrabBoxList(newGrabBoxList)
        }
      }
    }, [configInfo, pdfDocument, pageNumber, pdfLoaded])

    useEffect(() => grabBoxFun(), [grabBoxFun])

    const renderGrabBoxes = () => {
      return grabBoxList?.map((g, index) => (
        <GrabBox
          key={index}
          keyname={g.k.keyname}
          boxId={'box_' + index}
          height={g.secondY - g.firstY}
          width={g.secondX - g.firstX}
          left={g.left}
          top={g.top}
          setMode={setMode}
          hide={!g.displayGrabBox}
        />
      ))
    }
    return (
      <>
        <div>
          <CanvasWrapper>
            <PdfDisplayScrollX>
              <CanvasLayer
                id="canvas-layer"
                ref={refCanvasLayer}
                onMouseMove={onMouseMove}
                onTouchMove={onTouchMove}
                onMouseDown={onMouseDown}
                onTouchStart={onTouchStart}
                onDragStart={onDragTargetStart}
              >
                {!pdfLoaded ? <div className="loader" /> : renderGrabBoxes()}
              </CanvasLayer>
              <ParseDocumentCanvas
                id="parse-document-canvas"
                ref={refParseDocumentCanvas}
                onDragStart={onDragTargetStart}
              ></ParseDocumentCanvas>
              {pdfDocument?.numPages > 1 && (
                <Pagination
                  totalPages={pdfDocument?.numPages || 1}
                  currentPage={pageNumber}
                  // pageSize={pageSize}
                  loading={!pdfLoaded}
                  onPageChanged={handlePageNumber}
                />
              )}
            </PdfDisplayScrollX>
            {parseResultList?.length ? (
              <ResultTable data={parseResultList} caption={'Parse Result'} />
            ) : (
              ''
            )}
          </CanvasWrapper>
        </div>
      </>
    )
  }
)
