export const Compliance = Object.freeze([
  { text: 'PDF/A-1a Accessible Conformance', value: 'pdfA1a' },
  { text: 'PDF/A-1b Basic Conformance', value: 'pdfA1b' },
  { text: 'PDF/A-2b Basic Conformance', value: 'pdfA2b' },
  {
    text: 'PDF/A-2u Basic Conformance with Unicode Mapping',
    value: 'pdfA2u',
  },
  { text: 'PDF/A-2a Accessible Conformance', value: 'pdfA2a' },
  { text: 'PDF/A-3b Basic Conformance', value: 'pdfA3b' },
  {
    text: 'PDF/A-3u Basic Conformance with Unicode Mapping',
    value: 'pdfA3u',
  },
  { text: 'PDF/A-3a Accessible Conformance', value: 'pdfA3a' },
])
