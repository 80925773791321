import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { colors, fontSize, media, device } from '../../theme'
import { useTranslation } from '../../i18n/useTranslation'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  width: 100%;
  max-width: 280px;
  ${media.down(device.tablet)`
    max-width: calc(380px - 2rem);
  `}
  ${media.down(device.mobile)`
    max-width: 100%;
  `}
  input {
    min-height: 36px;
    max-width: 100% !important;
  }
`
const Label = styled.label`
  margin: 4px 0;
  display: block;
  font-size: ${fontSize.sm};
  ${media.down(device.tablet)`
    text-align: center;
  `}
  strong {
    .password-strength-weak & {
      color: red;
    }
    .password-strength-good & {
      color: lightgreen;
    }
    .password-strength-strong & {
      color: green;
    }
    .password-strength-verystrong & {
      color: darkgreen;
    }
  }
`
const Meter = styled.div`
  width: 100%;
  margin-top: -1px;
  height: 2px;
  background-color: ${colors.gray};
  div {
    height: 2px;
    .password-strength-weak & {
      background-color: red;
    }
    .password-strength-good & {
      background-color: green;
    }
    .password-strength-strong & {
      background-color: darkgreen;
    }
    .password-strength-verystrong & {
      background-color: darkgreen;
    }
  }
`

const PasswordStrengthRange = ({ password, children }) => {
  const t = useTranslation('tool_protectPdf')
  const [strengthLabel, setStrengthLabel] = useState('Weak')
  const [strength, setStrength] = useState(0)

  useEffect(() => {
    let pwStrength = 0

    if (password?.length < 6) {
      pwStrength = 0
    } else if (password?.length > 12) {
      pwStrength = 1
    }

    const regex = []
    regex.push('[A-Z]')
    regex.push('[a-z]')
    regex.push('[0-9]')
    regex.push('[$@$!%*#?&]')

    regex.forEach((ex) => {
      if (new RegExp(ex).test(password)) {
        pwStrength += 1
      }
    })

    if (password?.length < 6) {
      pwStrength = 1
    }

    switch (pwStrength) {
      case 0:
        setStrength(0)
        setStrengthLabel('')
        break
      case 1:
        setStrength(1)
        setStrengthLabel('weak')
        break
      case 2:
      case 3:
        setStrength(2)
        setStrengthLabel('good')
        break
      case 4:
        setStrength(3)
        setStrengthLabel('strong')
        break
      default:
        setStrength(4)
        setStrengthLabel('verystrong')
        break
    }
  }, [password])

  return (
    <Root className={`password-strength-${strengthLabel}`}>
      {children}
      {password && (
        <>
          <Meter>
            <div style={{ width: `${strength * 25}%` }} />
          </Meter>
          <Label>
            {t('passwordStrength')}:{' '}
            <strong>{t(`passwordStrength-${strengthLabel}`)}</strong>
          </Label>
        </>
      )}
    </Root>
  )
}
export default PasswordStrengthRange
