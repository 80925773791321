import styled from 'styled-components'

import { colors, media, device } from '../../../theme'

const _boxSize = 10
const ResizerPointer = styled.div`
  width: ${_boxSize}px;
  height: ${_boxSize}px;
  background-color: ${colors.primary};
  position: absolute;
  &.top {
    top: -${_boxSize / 2}px;
    // top: 0px;
    &.left {
      cursor: nw-resize;
    }
    &.right {
      cursor: ne-resize;
    }
  }
  &.bottom {
    bottom: -${_boxSize / 2}px;
    // bottom: 0px;
    &.left {
      cursor: sw-resize;
    }
    &.right {
      cursor: se-resize;
    }
  }
  &.left {
    left: -${_boxSize / 2}px;
    // left: 0px;
  }
  &.right {
    right: -${_boxSize / 2}px;
    // right: 0px;
  }
  ${media.down(device.mobile)`
  width: ${_boxSize + 10}px;
  height: ${_boxSize + 10}px;
  `}
`
const ResizeWrapper = styled.div`
  position: absolute;
  cursor: grab;
  border: 2px dashed red;
  &:active {
    cursor: grabbing;
  }
  .resizer {
    visibility: hidden;
  }
  &:hover .resizer {
    visibility: visible;
  }
`
export default ({
  setMode,
  boxId,
  keyname,
  height,
  width,
  left,
  top,
  hide,
}) => {
  // const onResizerMouseDown = (e) => {
  //   const { id } = e.target
  //   setMode(`resize-${id}-${boxId}`)
  //   e.preventDefault()
  // }
  // const onResizerTouchStart = (e) => {
  //   const { id } = e.target
  //   setMode(`resize-${id}-${boxId}`)
  //   e.preventDefault()
  // }
  // const onMouseDown = (e) => {
  //   if (e) {
  //     e.preventDefault()
  //     setMode(`move-${boxId}`)
  //   }
  // }
  return (
    <ResizeWrapper
      id={boxId}
      style={{
        height: height + 'px',
        width: width + 'px',
        left: left + 'px',
        top: top + 'px',
      }}
      title={keyname}
      className={hide ? 'hide' : ''}
      // onMouseDown={onMouseDown}
    >
      <ResizerPointer
        id="resizeTopLeft"
        className="top left resizer"
        //onMouseDown={onResizerMouseDown}
        // onTouchStart={onResizerTouchStart}
      />
      <ResizerPointer
        id="resizeTopRight"
        className="top right resizer"
        // onMouseDown={onResizerMouseDown}
        // onTouchStart={onResizerTouchStart}
      />
      <ResizerPointer
        id="resizeBottomLeft"
        className="bottom left resizer"
        // onMouseDown={onResizerMouseDown}
        // onTouchStart={onResizerTouchStart}
      />
      <ResizerPointer
        id="resizeBottomRight"
        className="bottom right resizer"
        // onMouseDown={onResizerMouseDown}
        // onTouchStart={onResizerTouchStart}
      />
    </ResizeWrapper>
  )
}
