import React from 'react'

export default function (props) {
  const { className } = props

  return (
    <div className={className ? className : ''}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 26">
        <path
          fill="currentColor"
          d="m14.176 23 1.432-8.727h.852L15.028 23h-.852Zm-3.972-2.42.137-.853h6.511l-.136.852h-6.512Zm.904 2.42 1.432-8.727h.852L11.96 23h-.852Zm-.392-5.454.136-.853h6.512l-.137.852h-6.511Z"
        />
        <path
          fill="currentColor"
          d="m19.7 6.583-7-6.312a.902.902 0 0 0-.317-.205A.986.986 0 0 0 12 0H2A2.121 2.121 0 0 0 .588.53 1.724 1.724 0 0 0 0 1.804v21.643c.002.477.213.935.588 1.273.374.338.882.529 1.412.53h16a2.121 2.121 0 0 0 1.412-.53c.375-.338.586-.796.588-1.273V7.215a.742.742 0 0 0-.073-.346.819.819 0 0 0-.227-.286ZM12 2.165l5.6 5.05H12v-5.05Zm6 21.282H2V1.803h8v5.41c.001.478.213.936.587 1.274.375.338.883.529 1.413.53h6v14.428Z"
        />
      </svg>
    </div>
  )
}
