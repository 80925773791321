import React from 'react'
import styled from 'styled-components'

import Button from '../../../controls/Button'
import AngleDownIcon from '../../../controls/icons/angleDown'
import SelectFileSource from '../../SelectFileSource'

import CompressPdfConfig from './CompressPdfConfig'
import SplitPdfConfig from './SplitPdfConfig'
import PdfToWordConfig from './PdfToWordConfig'
import PdfToExcelConfig from './PdfToExcelConfig'
import PdfToPowerpointConfig from './PdfToPowerpointConfig'
import PdfToEpub from './PdfToEpub'
import PdfToImage from './PdfToImage'
import ConvertToPdfConfig from './ConvertToPdfConfig'
import AddBarcodeConfig from './AddBarcodeConfig'
import AddWatermarkConfig from './AddWatermarkConfig'
import ExtractResourcesConfig from './ExtractResourcesConfig'
import PdfEditorConfig from './PdfEditorConfig'
import OrganizePdfConfig from './OrganizePdfConfig'
import MergePdfConfig from './MergePdfConfig'
import MergePdfOverlayConfig from './MergePdfOverlayConfig'
import PdfMetadataConfig from './PdfMetadataConfig'
import SignPdfConfig from './SignPdfConfig'
import PDFAConfig from './PDFAConfig'
import FlattenPdfConfig from './FlattenPdfConfig'
import AddPageNumber from './AddPageNumber'
import FindAndReplaceConfig from './FindAndReplaceConfig'

import { ToolName } from '../../../utils/enums'
import { colors, device, fontSize, media } from '../../../theme'
// import { useJobConfig } from '../../JobConfig/JobContext' unused import
import { useTranslation } from '../../../i18n/useTranslation'
import { useJobConfig } from '../../JobConfig/JobContext'
import RedactConfig from './RedactConfig'
import ProtectPdfConfig from './ProtectPdfConfig'
import RepairPdfConfig from './RepairPdfConfig'

const Root = styled(Button)`
  .dropdown-toggle {
    width: max-content;
    font-size: ${fontSize.sm};
    color: ${colors.textContrast};
    svg {
      fill: ${colors.textContrast};
    }
  }
`
const AddFilesWrapper = styled.div`
  margin-top: 16px;
  ${media.up(device.tablet)`
    display: flex;
    align-items: center;
  `}
`

const AddFilesInfo = styled.div`
  padding: 8px 0;
  ${media.up(device.tablet)`
  margin: 0 auto 0 0;
  `}
`
const ActionConfigGenerator = ({
  toolName,
  form,
  disabled,
  isPristine,
  onChange,
  inputFiles,
}) => {
  const t = useTranslation()
  const { maxFiles, minFilesRequired } = useJobConfig()
  if (!form) return <></>

  switch (toolName) {
    case ToolName.COMPRESS_PDF:
      return <CompressPdfConfig form={form} onChange={onChange} />
    case ToolName.FLATTEN_PDF:
      return <FlattenPdfConfig form={form} onChange={onChange} />
    case ToolName.REPAIR_PDF:
      return <RepairPdfConfig form={form} onChange={onChange} />
    case ToolName.ADD_PAGE_NUMBER:
      return (
        <AddPageNumber
          toolName={toolName}
          form={form}
          disabled={disabled}
          isPristine={isPristine}
          onChange={onChange}
          inputFiles={inputFiles}
        />
      )

    case ToolName.SPLIT_PDF:
      return (
        <SplitPdfConfig
          toolName={toolName}
          form={form}
          disabled={disabled}
          isPristine={isPristine}
          onChange={onChange}
          inputFiles={inputFiles}
        />
      )
    case ToolName.PDF_TO_WORD:
      return <PdfToWordConfig form={form} onChange={onChange} />
    case ToolName.PDF_TO_EXCEL:
      return <PdfToExcelConfig form={form} onChange={onChange} />
    case ToolName.PDF_TO_POWERPOINT:
      return <PdfToPowerpointConfig form={form} onChange={onChange} />
    case ToolName.PROTECT_PDF:
      return (
        <ProtectPdfConfig
          form={form}
          onChange={onChange}
          inputFiles={inputFiles}
        />
      )
    case ToolName.PDF_TO_IMAGE:
      return (
        <PdfToImage
          form={form}
          onChange={onChange}
          inputFiles={inputFiles}
          isPristine={isPristine}
        />
      )
    case ToolName.PDF_TO_EPUB:
      return (
        <PdfToEpub
          form={form}
          onChange={onChange}
          inputFiles={inputFiles}
          isPristine={isPristine}
        />
      )
    case ToolName.PDF_CONVERTER:
    case ToolName.IMAGE_TO_PDF:
    case ToolName.WORD_TO_PDF:
    case ToolName.POWERPOINT_TO_PDF:
    case ToolName.EXCEL_TO_PDF:
      return (
        <ConvertToPdfConfig
          toolName={toolName}
          form={form}
          onChange={onChange}
          inputFiles={inputFiles}
          isPristine={isPristine}
        />
      )

    case ToolName.ADD_BARCODE:
      return (
        <AddBarcodeConfig
          toolName={toolName}
          form={form}
          onChange={onChange}
          inputFiles={inputFiles}
          isPristine={isPristine}
        />
      )
    case ToolName.ADD_WATERMARK:
      return (
        <AddWatermarkConfig
          toolName={toolName}
          form={form}
          onChange={onChange}
          inputFiles={inputFiles}
          isPristine={isPristine}
        />
      )
    case ToolName.EXTRACT_RESOURCES:
      return (
        <ExtractResourcesConfig
          toolName={toolName}
          form={form}
          onChange={onChange}
          inputFiles={inputFiles}
        />
      )
    case ToolName['PDF/A']:
      return <PDFAConfig onChange={onChange} inputFiles={inputFiles} />
    case ToolName.PDF_EDITOR: {
      return <PdfEditorConfig toolName={toolName} inputFiles={inputFiles} />
    }
    case ToolName.SIGN_PDF: {
      return <SignPdfConfig toolName={toolName} inputFiles={inputFiles} />
    }
    case ToolName.REDACT_PDF: {
      return <RedactConfig toolName={toolName} inputFiles={inputFiles} />
    }
    case ToolName.ORGANIZE_PAGES: {
      return <OrganizePdfConfig toolName={toolName} inputFiles={inputFiles} />
    }
    case ToolName.FIND_AND_REPLACE: {
      return (
        <FindAndReplaceConfig
          form={form}
          toolName={toolName}
          inputFiles={inputFiles}
          onChange={onChange}
        />
      )
    }
    case ToolName.MERGE_PDF: {
      return (
        <>
          <AddFilesWrapper>
            <AddFilesInfo>
              <strong>{maxFiles - (inputFiles.length || 0)}</strong>{' '}
              {t('addFileInfo1')} <strong>{maxFiles}</strong>{' '}
              {t('addFileInfo2')}
            </AddFilesInfo>

            <Root className="btn btn--secondary" endIcon={AngleDownIcon}>
              <SelectFileSource
                alignDropdown="right"
                label={t('btnAddFile')}
                color="secondary"
              />
            </Root>
          </AddFilesWrapper>
          <MergePdfConfig
            toolName={toolName}
            form={form}
            onChange={onChange}
            inputFiles={inputFiles}
          />
        </>
      )
    }
    case ToolName.MERGE_PDF_OVERLAY: {
      return (
        <>
          <AddFilesWrapper>
            <AddFilesInfo>
              <strong>{maxFiles - (inputFiles.length || 0)}</strong>{' '}
              {t('addFileInfo1')} <strong>{maxFiles}</strong>{' '}
              {t('addFileInfo2')}
            </AddFilesInfo>
            {!minFilesRequired ? (
              <Root className="btn btn--secondary" endIcon={AngleDownIcon}>
                <SelectFileSource
                  alignDropdown="right"
                  label={t('btnAddOverlayFile')}
                  color="secondary"
                />
              </Root>
            ) : (
              ''
            )}
          </AddFilesWrapper>
          <MergePdfOverlayConfig
            toolName={toolName}
            form={form}
            onChange={onChange}
            inputFiles={inputFiles}
          />
        </>
      )
    }
    case ToolName.PDF_METADATA:
      return (
        <PdfMetadataConfig
          toolName={toolName}
          form={form}
          onChange={onChange}
          inputFiles={inputFiles}
        />
      )
    default:
      return <></>
  }
}

export default ActionConfigGenerator
