import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import Checkbox from '../../../../controls/Checkbox'
import FormSection from '../../../../controls/FormSection'
import pdfFileIcon from '../../../../controls/icons/pdfFile'
import globeIcon from '../../../../controls/icons/globe'
import compressIcon from '../../../../controls/icons/compress'
import { isMimeTypeSupported } from '../../../../utils/mimeTypes'
import { useJobConfig } from '../../../JobConfig/JobContext'
import { ToolName } from '../../../../utils/enums'
import { useTranslation } from '../../../../i18n/useTranslation'

const RepairPdfConfig = ({ onChange, form, inputFiles, toolName }) => {
  const { updateActionConfig } = useJobConfig()
  const t = useTranslation('tool_repairPdf')

  return <></>
}

export default RepairPdfConfig
