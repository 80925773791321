import React from 'react'
import Button from '../Button'
import styled from 'styled-components'
import { device, fontSize, media, spacing } from '../../theme'
export const ButtonRoundCorner = styled(Button)`
  border-width: 2px;
  border-style: solid;
  border-radius: 16px;
  min-height: 32px;
  height: auto;
  min-width: 72px;
  padding: 3px 14px 5px;
  font-size: 14px;
  font-weight: 700;
`

export const Col = styled.div`
  flex: 1 0 33.33%;
  // padding: 10px;
  min-width: 150px;
  ${media.down(device.mobile)`
    width: 100%;
    max-width: 100%;
    min-width: unset;
    `}
`
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  // margin: -10px;
  align-items: center;
  margin: 16px 0;
  column-gap: 5px;
  width: 100%;
  label {
    font-size: ${fontSize.sm};
    margin-right: ${spacing(2)};
    width: 100%;
  }
`
