import styled from "styled-components";
import InputTextField from '../../../../controls/InputTextField'

export const CustomSelect = styled(InputTextField)`
  padding: 0.5px;
  .formRow__input {
    margin: 4px 0;
    select {
      max-width: 100%;
    }
  }
`