import React, { useState } from 'react'
import styled from 'styled-components'
import { colors, fontSize } from '../../theme'

// Styled Components
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 16px 0;
`

const ToggleButton = styled.div`
  width: 50px;
  height: 25px;
  background-color: ${({ toggled }) => (toggled ? colors.secondary : '#ccc')};
  border-radius: 25px;
  position: relative;
  transition: background-color 0.3s;
`

const ToggleCircle = styled.div`
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: ${({ toggled }) => (toggled ? '26px' : '2px')};
  transition: left 0.3s;
`

const ToggleButtonGroup = ({ onChange, label, checked, name }) => {
  const [toggled, setToggled] = useState(checked)
  const handleToggle = () => {
    const newValue = !toggled
    setToggled(newValue)
    const event = {
      target: {
        name,
        checked: newValue,
        type: 'checkbox',
      },
    }
    onChange(event)
  }

  return (
    <>
      <label className="formRow__label">{label}</label>
      <ToggleContainer onClick={handleToggle}>
        <ToggleButton toggled={toggled}>
          <ToggleCircle toggled={toggled} />
        </ToggleButton>
      </ToggleContainer>
    </>
  )
}

export default ToggleButtonGroup
