import { useState, useCallback, useContext } from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../../theme'

const Switch = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 64px;
  height: 32px;
  border-radius: 16px;
  overflow: hidden;
  background-color: #ccc;
  cursor: pointer;
  &.checked {
    background-color: #e31b23;
  }
  &.loading {
    background-color: ${colors.bgLight};
  }
`

const Slider = styled.div`
  position: absolute;
  left: 4px;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  transition: left 0.2s;
  border-radius: 50%;
  border: solid 2px transparent;
  &.checked {
    left: 36px;
  }
  &.loading {
    border-top-color: ${colors.secondary};
    animation: rotate 0.9s infinite linear;
    left: 20px;
  }
`

export default (props) => {
  const { checked, loading, disabled, name, onChange } = props

  const classes = []
  if (checked) {
    classes.push('checked')
  }

  if (loading) {
    classes.push('loading')
  }

  const onCheckChange = (e) => {
    if (!loading && !disabled && typeof onChange === 'function') {
      onChange(e)
    }
  }

  return (
    <Switch className={classes.join(' ')}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        style={{ display: 'none' }}
        onChange={onCheckChange}
      />
      <Slider className={classes.join(' ')} />
    </Switch>
  )
}
