import { useEffect, useRef } from 'react'

const Accordion = ({ title, children }) => {
  const accordionCard = useRef()
  const accordionHeader = useRef()
  const accordionBody = useRef()

  const onClick = (e) => {
    const card = e.currentTarget.parentElement
    const isOpen = card.classList.contains('accordion__card--open')
    e.currentTarget
      .closest('.accordion')
      .querySelectorAll('.accordion__body')
      .forEach((item) => {
        item.style.height = 0
        item.parentElement.classList.remove('accordion__card--open')
      })
    if (!isOpen) {
      card.classList.add('accordion__card--open')
      const body = e.currentTarget.nextSibling
      body.style.height = body.scrollHeight + 'px'
    }
  }

  useEffect(() => {
    const isOpen = accordionCard.current.classList.contains(
      'accordion__card--open'
    )

    if (isOpen) {
      accordionBody.current.style.height = 0

      accordionBody.current.style.height =
        accordionBody.current.scrollHeight + 'px'
      // console.log(
      //   'accordionBody.current.scrollHeight',
      //   accordionBody.current.scrollHeight,
      //   accordionBody.current
      // )
    }
  }, [children])

  return (
    <div className="accordion" data-ui-role="accordion">
      <div className="accordion__card" ref={accordionCard}>
        <div
          className="accordion__header"
          ref={accordionHeader}
          onClick={onClick}
        >
          <h3>{title}</h3>
        </div>
        <div className="accordion__body" ref={accordionBody}>
          {children}
        </div>
      </div>
    </div>
  )
}
export default Accordion
