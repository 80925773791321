import styled from 'styled-components'
import { layout } from '../../theme'

export const Thumbnail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  //   height: 300px;
  img {
    width: auto;
    height: auto;
    max-width: 300px;
    max-height: 300px;
    box-shadow: ${layout.elevate(2)};
    border: solid 1px var(--body-background);
  }
  &:hover {
    > div {
      display: flex;
    }
  }
`
export const FileItem = styled.li`
  padding: 4px 0px;
  margin: 0px;
  animation: 0.25s ease-in 0s 1 normal none running iFEGFp;
`
