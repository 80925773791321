import React, { useContext } from 'react'
import {
  Tab,
  TabList,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
} from '@fluentui/react-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { TeamsFxContext } from '../TeamFxContextProvider'
import { downloadFile } from '../../store/viewer/downloadApiSlice'
import { fileUpload } from '../../service/document/fileUpload'
import { executeFileUpload } from '../../store/notification/action'
import { isMimeTypeSupported } from '../../utils/mimeTypes'
import { createId } from '../../utils/createId'
import {
  getFileFromURL,
  redirectToViewer,
  getToolByName,
  getTools,
} from '../../utils/helper'
import toolList from '../../assets/data/toolList.json'
import { PopupScreen, ToolName } from '../../utils/enums'
import { executePdfJob } from '../../service/document/executePdfJob'
import { ThreeDotsIcon, ToggleButton } from './styles'
import { useJobConfig } from '../JobConfig/JobContext'
import { useTranslation } from '../../i18n/useTranslation'

const ToggleMenu = ({ item }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = useTranslation()
  const { teamsUserCredential } = useContext(TeamsFxContext)
  const { connectionId } = useSelector((state) => state.hub)
  const { onFileSelect, openStartJobScreen } = useJobConfig()
  const tools = getTools(item)
  const fileUploadHandler = (tool, files, id) => {
    dispatch(executeFileUpload({ tool, files, docIdExt: id }))
      .then((res) => {
        // !res.payload
      })
      .catch((e) => {})
  }

  const download = async (itemId, driveId, name) => {
    const res = await dispatch(
      downloadFile({ teamsUserCredential, itemId, driveId })
    )
    if (res.error) return
    res?.payload && window.location.assign(res.payload)
  }

  // const signPdf = async (itemId, driveId, name) => {
  //   const _fileUrl = await dispatch(
  //     downloadFile({ teamsUserCredential, itemId, driveId })
  //   )
  //   if (_fileUrl.error) return
  //   redirectToViewer(navigate, _fileUrl.payload, name, 'sign-viewer')
  // }
  // const organizePages = async (itemId, driveId, name) => {
  //   const id = createId()
  //   let file
  //   try {
  //     const _fileUrl = await dispatch(
  //       downloadFile({ teamsUserCredential, itemId, driveId })
  //     )
  //     if (_fileUrl.error) return
  //     const tool = getToolByName(ToolName.ORGANIZE_PAGES)
  //     file = await getFileFromURL(_fileUrl.payload, name)
  //     fileUploadHandler(tool, [file], id)
  //     navigate('/OrganizePdf', {
  //       state: { tool, docIdExt: id },
  //     })
  //   } catch (error) {
  //     return
  //   }
  // }
  const convert = async (itemId, driveId, name) => {
    // const id = createId()
    let file
    try {
      const _fileUrl = await dispatch(
        downloadFile({ teamsUserCredential, itemId, driveId })
      )
      if (_fileUrl.error) return
      const tool = getToolByName(ToolName.PDF_CONVERTER)
      file = await getFileFromURL(_fileUrl.payload, name)
      // fileUploadHandler(tool, [file], id)
      onFileSelect(tool, [file], true, PopupScreen.StartJob, null)
    } catch (error) {
      return
    }
  }
  const pdfAction = async (toolName, itemId, driveId, itemName) => {
    let file
    try {
      const _fileUrl = await dispatch(
        downloadFile({ teamsUserCredential, itemId, driveId })
      )
      if (_fileUrl.error) return
      const tool = { toolName }
      file = await getFileFromURL(_fileUrl.payload, itemName)
      // fileUploadHandler(tool, [file], id)
      onFileSelect(tool, [file], true, PopupScreen.StartJob, null)
    } catch (error) {
      return
    }
  }
  const getToggleList = (itemId, driveId, itemName) => {
    if (isMimeTypeSupported(itemName, 'pdf')) {
      let pdfTools = [
        {
          label: 'Download',
          action: () => download(itemId, driveId, itemName),
        },
      ]
      tools.map((e) =>
        pdfTools.push({
          label: t(`lblTool_${e.name}`),
          action: () => pdfAction(e.name, itemId, driveId, itemName),
        })
      )
      return pdfTools
    }
    if (isMimeTypeSupported(itemName, 'convertable')) {
      return [
        {
          label: getToolByName(ToolName.PDF_CONVERTER)?.title,
          action: () => convert(itemId, driveId, itemName),
        },
        {
          label: 'Download',
          action: () => download(itemId, driveId, itemName),
        },
      ]
    }
    return [
      { label: 'Download', action: () => download(itemId, driveId, itemName) },
    ]
  }
  const handleToggleClick = (event) => {
    event.stopPropagation()
  }
  return (
    <div onClick={handleToggleClick}>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <ToggleButton
            icon={<ThreeDotsIcon toolName={'verticalThreeDots'} />}
          />
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {getToggleList &&
              getToggleList(item.id, item.driveId, item.name)?.map((e, i) => (
                <MenuItem key={e?.label + '_' + i} onClick={e.action}>
                  {e.label}
                </MenuItem>
              ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  )
}

export default ToggleMenu
