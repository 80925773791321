const actions = {
  startHub: () => async (dispatch) => {
    await dispatch({ type: 'HUB_START' })
  },
  stopHub: () => async (dispatch) => {
    await dispatch({ type: 'HUB_STOP' })
  },
}

export default actions
