import React, { useMemo, useState } from 'react'

import FormSection from '../../../../controls/FormSection'
import ToggleButton from '../../../../controls/ToolBarToggleButton'
import { useJobConfig } from '../../../JobConfig/JobContext'

import { useTranslation } from '../../../../i18n/useTranslation'

import { validate } from 'validate.js'

import { Col, Row } from '../../../../controls/Common'

const MergePdfOverlayConfig = ({ onChange, inputFiles }) => {
  const { actionConfig, setIsValid } = useJobConfig()
  const t = useTranslation('tool_mergePdfOverlay')
  const [errors, setErrors] = useState()
  const filesReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )

  const checkValid = (error) => {
    if (error) {
      setIsValid(false)
      return false
    }
    setIsValid(true)
    return true
  }

  const formValidate = (form) => {
    let isValid = true

    return isValid
  }

  const handleFieldChange = (event) => onChange(event, formValidate)
  const bindInput = (name) => {
    return {
      name,
      onChange: handleFieldChange,
      value: actionConfig[name],
    }
  }
  return <></>
}

export default MergePdfOverlayConfig
