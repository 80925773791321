import { excludeMargin, ToolName } from './enums'
import { getActionConfig, getToolConfig } from './helper'
import { getMargin, getPageNumberFormat, getPositionKey } from './toolHelper'

const getCustomSelection = (jobConfig) => {
  if (jobConfig.customSelection && Array.isArray(jobConfig.customSelection)) {
    return jobConfig.customSelection.join(',')
  }
  return 'all'
}

const setActionConfig = (actionConfig, newActions) =>
  actionConfig.map((action) => ({
    ...action,
    parameters: Object.keys(newActions[action.actionType]).map((p) => ({
      name: p,
      value: newActions[action.actionType][p],
    })),
  }))
const uniqueArrOfObj = (arr = [], filterKey) => {
  const unique = (key) => (value, index, array) => {
    // Ensure the value is not undefined
    return (
      value &&
      array.findIndex((obj) => obj && obj[key] === value[key]) === index
    )
  }
  return arr.filter(unique(filterKey))
}

export const UpdateConfig = (toolName, jobConfig) => {
  let _actionsConfig = getActionConfig(toolName)
  let cpyJobConfig = {}

  switch (toolName) {
    case ToolName.COMPRESS_PDF: {
      cpyJobConfig = {}
      cpyJobConfig.optimize = {
        profile: jobConfig.profile === 'max' ? 'compressMax' : 'compress',
      }

      break
    }

    case ToolName.SPLIT_PDF: {
      cpyJobConfig = {}
      if (jobConfig.splitType === 'byPageNr') {
        let splitByPageNr = jobConfig.splitByPageNr.trim()
        if (splitByPageNr.indexOf(',') > 0) {
          const pageNrs = splitByPageNr
            .split(',')
            .map((x) => parseInt(x.trim(), 10))
            .sort((a, b) => a - b)
          cpyJobConfig.split = {
            splitSequence: pageNrs.join(','),
          }
        } else {
          cpyJobConfig.split = {
            splitByPageNr: splitByPageNr,
          }
        }
      } else if (jobConfig.splitType === 'recurring') {
        cpyJobConfig.split = {
          splitRecurring: jobConfig.splitRecurring.trim(),
        }
      } else if (jobConfig.splitType === 'byBarcode') {
        delete cpyJobConfig.split
        // replace the split action with splitByBarcode
        _actionsConfig = [
          ..._actionsConfig.filter((e) => e.actionType !== 'split'),
          {
            actionType: 'splitByBarcode',
            parameters: [],
          },
        ]
        cpyJobConfig.splitByBarcode = {
          text: jobConfig.splitBarcodeText.trim(),
          filter: jobConfig.splitBarcodeFilterType,
          type: jobConfig.splitBarcodeType,
          splitPage: jobConfig.splitBarcodePage,
        }
      } else if (jobConfig.splitType === 'byText') {
        delete cpyJobConfig.split
        cpyJobConfig.splitByText = {
          splitText: jobConfig.splitText.trim(),
          splitPage: jobConfig.splitTextPage,
        }
      }

      break
    }
    case ToolName.PDF_TO_WORD:
    case ToolName.PDF_TO_EXCEL:
    case ToolName.PDF_TO_POWERPOINT: {
      cpyJobConfig = {}
      cpyJobConfig.convertFromPdf = {
        outputFormat: jobConfig.outputFormat,
        quality: { ...jobConfig.quality },
      }

      break
    }

    case ToolName.ORGANIZE_PAGES: {
      cpyJobConfig = {}
      cpyJobConfig.produce = {
        ...jobConfig.produce,
      }

      break
    }

    case ToolName.EXTRACT_RESOURCES: {
      cpyJobConfig = {}
      cpyJobConfig.extractResources = {
        extract: jobConfig.extract,
      }

      break
    }
    case ToolName.PDF_TO_IMAGE: {
      cpyJobConfig = {}
      const pageSequence =
        jobConfig.pageSelectionType === 'all'
          ? 'all'
          : jobConfig.pageSelectionType === 'first'
          ? '1'
          : getCustomSelection(jobConfig)
      cpyJobConfig.image = {
        pageSequence,
        imageFormat: jobConfig.imageFormat,
        width: jobConfig.width,
      }

      break
    }
    case ToolName.PDF_TO_EPUB: {
      cpyJobConfig = {}
      if (jobConfig?.coverType === 'firstPage')
        cpyJobConfig.firstPageCover = 'true'
      else cpyJobConfig.firstPageCover = 'false'
      break
    }

    case ToolName.PDF_CONVERTER:
    case ToolName.IMAGE_TO_PDF:
    case ToolName.WORD_TO_PDF:
    case ToolName.POWERPOINT_TO_PDF:
    case ToolName.EXCEL_TO_PDF: {
      cpyJobConfig = { convertToPdf: true }
      const size = jobConfig.fileSize || 0
      const ocr = jobConfig.ocr

      if (jobConfig.optimize) {
        // filter out if it is duplicated
        _actionsConfig = uniqueArrOfObj(
          _actionsConfig.concat({
            actionType: 'optimize',
            parameters: [],
          }),
          'actionType'
        )
        cpyJobConfig.optimize = {
          profile: jobConfig.optimize,
        }
      }
      if (size > 1 && jobConfig.merge) {
        // filter out if it is duplicated
        _actionsConfig = uniqueArrOfObj(
          _actionsConfig.concat({
            actionType: 'merge',
            parameters: [],
          }),
          'actionType'
        )
        cpyJobConfig.merge = true
      }
      if (ocr) {
        // filter out if it is duplicated
        _actionsConfig = uniqueArrOfObj(
          _actionsConfig.concat({
            actionType: 'convertFromPdf',
            parameters: [],
          }),
          'actionType'
        )

        cpyJobConfig.convertFromPdf = {
          outputFormat: 'pdfocr',
          quality: 'high',
        }
      }

      break
    }

    case ToolName.MERGE_PDF: {
      cpyJobConfig = {}
      const size = jobConfig.fileSize || 0
      const ocr = jobConfig.ocr

      if (ocr) {
        // filter out if it is duplicated
        _actionsConfig = uniqueArrOfObj(
          _actionsConfig.concat({
            actionType: 'convertFromPdf',
            parameters: [],
          }),
          'actionType'
        )
        cpyJobConfig.convertFromPdf = {
          outputFormat: 'pdfocr',
          quality: 'high',
        }
      }
      if (jobConfig.optimize) {
        // filter out if it is duplicated
        _actionsConfig = uniqueArrOfObj(
          _actionsConfig.concat({
            actionType: 'optimize',
            parameters: [],
          }),
          'actionType'
        )
        cpyJobConfig.optimize = {
          profile: jobConfig.optimize,
        }
      }

      cpyJobConfig.merge = true

      break
    }

    case ToolName.ADD_BARCODE: {
      cpyJobConfig = {}
      const pageSequence =
        jobConfig.pageSelectionType === 'all'
          ? 'all'
          : jobConfig.pageSelectionType === 'first'
          ? '1'
          : getCustomSelection(jobConfig)
      cpyJobConfig.addBarcode = {
        BarcodeType: jobConfig.barcodeType,
        Text: jobConfig.barcodeText,
        Width: Math.round(jobConfig.width).toString(),
        Height: Math.round(jobConfig.height).toString(),
        PageSequence: pageSequence,
        AlignX: jobConfig.xalign,
        AlignY: jobConfig.yalign,
        Rotate: jobConfig.rotate.toString(),
        MarginX: Math.round(jobConfig.marginX).toString(),
        MarginY: Math.round(jobConfig.marginY).toString(),
        BarcodeColor: jobConfig.barcodeColor,
        BackgroundColor: jobConfig.backgroundColor,
        HideText: jobConfig.hideText.toString(),
      }

      break
    }
    case ToolName.ADD_WATERMARK: {
      cpyJobConfig = {}
      const pageSequence =
        jobConfig.pageSelectionType === 'all'
          ? 'all'
          : jobConfig.pageSelectionType === 'first'
          ? '1'
          : getCustomSelection(jobConfig)
      if (jobConfig.stampType === 'text') {
        cpyJobConfig.stamp = {
          stampType: jobConfig.stampType,
          PageSequence: pageSequence,
          AlignX: jobConfig.xalign,
          AlignY: jobConfig.yalign,
          Alpha: jobConfig.opacity.toString(),
          Rotate: jobConfig.rotate.toString(),
          Text: jobConfig.text,
          Size: Math.ceil(jobConfig.fontSize).toString(),
          fontFamily: jobConfig.fontFamily,
          fontBold: jobConfig.fontBold,
          fontItalics: jobConfig.fontItalics,
          fontUnderline: jobConfig.fontUnderline,
          fontColor: jobConfig.fontColor,
          StampPosition: jobConfig.showOnlyInPrint
            ? 'annotation'
            : jobConfig.stampPosition,
          Mode: jobConfig.mode,
          LineWidth: jobConfig.lineWidth.toString(),
          MarginX: Math.round(jobConfig.marginX).toString(),
          MarginY: Math.round(jobConfig.marginY).toString(),
          ShowOnlyInPrint: jobConfig.showOnlyInPrint,
        }
      } else if (jobConfig.stampType === 'image') {
        cpyJobConfig.stamp = {
          stampType: jobConfig.stampType,
          image: JSON.stringify({
            DocBlobRef: jobConfig.docBlobRef,
            Rectangle: {
              Width: Math.ceil(jobConfig.width),
              Height: Math.ceil(jobConfig.height),
            },
          }),
          PageSequence: pageSequence,
          AlignX: jobConfig.xalign,
          AlignY: jobConfig.yalign,
          Alpha: jobConfig.opacity.toString(),
          Rotate: jobConfig.rotate.toString(),
          StampPosition: jobConfig.showOnlyInPrint
            ? 'annotation'
            : jobConfig.stampPosition,
          MarginX: Math.round(jobConfig.marginX).toString(),
          MarginY: Math.round(jobConfig.marginY).toString(),
          ShowOnlyInPrint: jobConfig.showOnlyInPrint,
        }
      }

      break
    }

    case ToolName.PDF_METADATA: {
      cpyJobConfig = {}
      const permissions = []
      if (jobConfig.allowAssembly) permissions.push('assemble')
      if (jobConfig.allowCopy) permissions.push('copy')
      // if (!jobConfig.allowDegradedPrinting) permissions.push('digitalPrint')
      if (jobConfig.allowFillIn) permissions.push('fillForms')
      if (jobConfig.allowModifyAnnotations) permissions.push('annotate')
      if (jobConfig.allowModifyContents) permissions.push('modify')
      if (jobConfig.allowPrint) permissions.push('print')
      if (jobConfig.allowScreenReaders) permissions.push('supportDisabilities')

      cpyJobConfig.editMetadata = {
        title: jobConfig.title,
        subject: jobConfig.subject,
        author: jobConfig.author,
        producer: jobConfig.producer,
        creator: jobConfig.creator,
        keywords: jobConfig.keywords,
        permissions: permissions.join(','),
        securityType: jobConfig.securityType,
        // pdfProducer: values.pdfProducer,
      }
      break
    }

    case ToolName.ADD_PAGE_NUMBER: {
      cpyJobConfig = {}
      const pageNumberFormat = getPageNumberFormat()
      const margin = getMargin()
      const showHorizontalMargin = !excludeMargin.includes(
        getPositionKey(jobConfig?.position?.AlignX, jobConfig?.position?.AlignY)
      )
      const isCustomMargin = jobConfig.margin === margin[3].value

      const _customMarginX = showHorizontalMargin ? jobConfig.marginX : 0
      const _marginX = showHorizontalMargin ? jobConfig.margin : 0
      const marginX = isCustomMargin ? _customMarginX : _marginX
      const marginY = isCustomMargin ? jobConfig.marginY : jobConfig.margin

      const _pageNumberFormat =
        jobConfig.pageNumberFormat === pageNumberFormat[3].value
          ? jobConfig.customText?.trim()
          : jobConfig.pageNumberFormat?.replaceAll('#', '')
      cpyJobConfig.addPageNumber = {
        ...jobConfig.position,
        SkipFirstPage: jobConfig.skipFirstPage.toString(),
        MarginY: Math.round(marginY).toString(),
        MarginX: Math.round(marginX).toString(),
        isItalic: jobConfig.isItalic.toString(),
        isBold: jobConfig.isBold.toString(),
        PageNumberFormat: _pageNumberFormat,
        FontColor: jobConfig.fontColor,
        FontSize: Math.round(jobConfig.fontSize).toString(),
      }
      break
    }
    case ToolName['PDF/A']: {
      cpyJobConfig = {}

      cpyJobConfig.pdfa = {
        Compliance: jobConfig?.compliance,
        Linearize: jobConfig?.linearize?.toString(),
      }
      break
    }
    case ToolName.FIND_AND_REPLACE: {
      cpyJobConfig = {}
      const pageSequence =
        jobConfig.pageSelectionType === 'all'
          ? 'all'
          : jobConfig.pageSelectionType === 'first'
          ? '1'
          : getCustomSelection(jobConfig)

      cpyJobConfig.findAndReplace = {
        pageSequence,
        oldText: jobConfig.oldText,
        newText: jobConfig.newText,
      }
      break
    }

    case ToolName.FLATTEN_PDF: {
      cpyJobConfig = {}
      cpyJobConfig.flattenPdf = true

      break
    }
    case ToolName.PROTECT_PDF: {
      cpyJobConfig = {}
      cpyJobConfig.protect = { UserPassword: jobConfig.password }

      break
    }
    case ToolName.REDACT_PDF: {
      cpyJobConfig = {}

      cpyJobConfig[toolName] = {
        ...((jobConfig && jobConfig[toolName]) || {}),
      }
      break
    }
    case ToolName.REPAIR_PDF: {
      cpyJobConfig = {}

      cpyJobConfig.repair = {}
      break
    }

    default: {
      const toolConfig = getToolConfig(toolName)
      cpyJobConfig = { ...toolConfig }

      break
    }
  }
  _actionsConfig = setActionConfig(_actionsConfig, cpyJobConfig)
  return _actionsConfig
}

export const formatDocs = (data = [], docIdExt, file) => {
  if (!data?.length) return data
  return data?.map((f) => {
    return {
      docIdExt,
      name: f?.name,
      docBlobRef: f?.docBlobRef,
      fileName: file?.name,
      status: f?.status,
      docMetadata: {
        pageCount: f?.pageCount,
        size: file?.size,
        ...(f?.docMetadata || {}),
      },
      ...((f.error && { error: f.error }) || {}),
    }
  })
}
