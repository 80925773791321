import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import Checkbox from '../../../../controls/Checkbox'
import FormSection from '../../../../controls/FormSection'
import pdfFileIcon from '../../../../controls/icons/pdfFile'
import globeIcon from '../../../../controls/icons/globe'
import compressIcon from '../../../../controls/icons/compress'
import { isMimeTypeSupported } from '../../../../utils/mimeTypes'
import { useJobConfig } from '../../../JobConfig/JobContext'
import { ToolName } from '../../../../utils/enums'
import { useTranslation } from '../../../../i18n/useTranslation'
import PageNumberForm from './components/PageNumberForm'
import { validate } from 'validate.js'
import { constraint1 } from './schema'
import {
  getMargin,
  getPageNumberFormat,
  getPositionKey,
} from '../../../../utils/toolHelper'

const AddPageNumber = ({
  toolName,
  form,
  disabled,
  // isPristine,
  onChange,
  inputFiles,
}) => {
  const { updateActionConfig, actionConfig, setIsValid, isValid } =
    useJobConfig()
  const [popupOpen, setPopupOpen] = useState(false)
  const [errors, setErrors] = useState()
  const t = useTranslation('tool_addPageNumber')
  const excludeMargin = ['0-1', '2-1']
  const filesReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )

  const margin = getMargin(t)
  const pageNumberFormat = getPageNumberFormat(t)

  const showHorizontalMargin = !excludeMargin.includes(
    getPositionKey(
      actionConfig?.position?.AlignX,
      actionConfig?.position?.AlignY
    )
  )

  const checkValid = (error) => {
    if (error) {
      setIsValid(false)
      return false
    }
    setIsValid(true)
    return true
  }

  const formValidate = (form) => {
    const error = validate(form, constraint1(t))
    setErrors(error || null)
    const isValid = checkValid(error)
    return isValid
  }
  const bindInput = (name) => {
    return {
      name,
      onChange: (event) => onChange(event, formValidate),
      value: actionConfig[name],
    }
  }
  useEffect(() => {
    const values = {
      position: { AlignX: 'right', AlignY: 'bottom' },
      skipFirstPage: false,
      margin: margin[1].value,
      marginX: margin[1].value,
      marginY: margin[1].value,
      customMargin: '',
      pageNumberFormat: pageNumberFormat[0].value,
      customText: 'customInputText',
      fontColor: '#000000ff',
      isBold: false,
      isItalic: false,
      fontSize: '12',
    }
    updateActionConfig(values)
  }, [])

  return (
    <>
      {popupOpen && (
        <PageNumberForm
          onChange={onChange}
          isValid={isValid}
          // isPristine={isPristine}
          errors={errors}
          bindInput={bindInput}
          // bindError={bindError}
          formValidate={formValidate}
          margin={margin}
          pageNumberFormat={pageNumberFormat}
          filesReady={filesReady}
          showHorizontalMargin={showHorizontalMargin}
          setPopupOpen={setPopupOpen}
        />
      )}

      <FormSection>
        <FormRow>
          <ButtonGroup
            name="configure"
            items={[
              {
                id: 'btnConfigure',
                value: 'true',
                label: t('configure'),
              },
            ]}
            value={'true'}
            type="radio"
            onClick={() => {
              setPopupOpen(true)
            }}
          />
        </FormRow>
      </FormSection>
    </>
  )
}

export default AddPageNumber
