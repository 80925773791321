const checkIFT = (t) => (key) => {
  return t ? t(key) : key
}

export const getMargin = (t) => {
  return [
    { value: 5, text: checkIFT(t)('small') },
    { value: 10, text: checkIFT(t)('recommended') },
    { value: 15, text: checkIFT(t)('big') },
    {
      value: 'custom',
      text: checkIFT(t)('custom'),
    },
  ]
}

export const getPageNumberFormat = (t) => {
  return [
    { value: '#', text: checkIFT(t)('pageNumberOnly') },
    { value: checkIFT(t)('page#'), text: checkIFT(t)('page#') },
    {
      value: checkIFT(t)('page#ofTotalPages'),
      text: checkIFT(t)('page#ofTotalPages'),
    },
    {
      value: 'custom',
      text: checkIFT(t)('custom'),
    },
  ]
}
export const getPositionKey = (alignX, alignY) => {
  const map = {
    'left-top': '0-0',
    'center-top': '0-1',
    'right-top': '0-2',
    'left-middle': '1-0',
    'center-middle': '1-1',
    'right-middle': '1-2',
    'left-bottom': '2-0',
    'center-bottom': '2-1',
    'right-bottom': '2-2',
  }

  const key = `${alignX}-${alignY}`
  return map[key] || null
}
