import styled from 'styled-components'

import { colors } from '../../theme'
import PasswordInput from '../PasswordInput'
import { useTranslation } from '../../i18n/useTranslation'

const ReadonlyValue = styled.div`
  height: 40px;
  min-height: 40px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  color: ${colors.text};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const InputTextField = (props) => {
  const {
    label,
    name,
    value,
    errorMessage,
    inputType,
    placeholder,
    items,
    readonly,
    wrap,
    disabled,
    onChange,
    className,
    hint,
  } = props
  const t = useTranslation()

  const classNames = []
  if (className) classNames.push(className)

  function renderInput() {
    if (readonly) {
      if (inputType === 'select') {
        const selectedItem = items.find((item) => item.value === value)
        return (
          <ReadonlyValue>{selectedItem && selectedItem.text}</ReadonlyValue>
        )
      }
      return <ReadonlyValue>{value}</ReadonlyValue>
    }
    if (inputType === 'select') {
      const _items = [...items]
      if (value === undefined || value === '') {
        _items.push({
          text: t('lblPleaseSelect'),
          value: '',
        })
      }
      return (
        <select
          onChange={onChange}
          name={name}
          value={value}
          disabled={disabled}
        >
          {_items.map((item) => (
            <option key={item.value} value={item.value}>
              {item.text}
            </option>
          ))}
        </select>
      )
    }

    if (inputType === 'multiline') {
      return (
        <textarea
          type={inputType}
          placeholder={placeholder}
          onChange={onChange}
          name={name}
          disabled={disabled}
          value={value}
        ></textarea>
      )
    }

    if (inputType === 'password') {
      return (
        <PasswordInput
          onChange={onChange}
          name={name}
          value={value}
          disabled={disabled}
        />
      )
    }

    if (inputType === 'setPassword') {
      return (
        <PasswordInput
          onChange={onChange}
          name={name}
          value={value}
          passwordPolicy
          disabled={disabled}
        />
      )
    }

    return (
      <input
        type={inputType}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        value={value}
        disabled={disabled}
      />
    )
  }

  return (
    <div className={classNames.join(' ')}>
      {label && <label className="formRow__label">{label}</label>}
      <div className="formRow__input">{renderInput()}</div>
      {hint && <div className="formRow__hint">{hint}</div>}
      {errorMessage && (
        <div className="formRow__errorMessage">{errorMessage}</div>
      )}
    </div>
  )
}

export default InputTextField
