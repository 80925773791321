import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateTime } from '../../utils/valueFormatter'
import FileList from './FileList'
import styled from 'styled-components'
import Pagination from '../../controls/Pagination'
import { jobHistoryOverviewThunk } from '../../store/jobHistory/jobHistoryAsyncThunk'

import { useTranslation } from '../../i18n/useTranslation'
import { colors, fontSize } from '../../theme'
import { LoadingStates } from '../../utils/enums'
import { TeamsFxContext } from '../../components/TeamFxContextProvider'
import { withMessageBox } from '../../controls/MessageBox'

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: var(--gray-100);
`
export const DocumentList = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
  padding: 8px 20px 62px 16px;
  width: auto;
  // height: 88vh;
`
export const DocumentListHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: ${fontSize.h2};
  font-weight: 700;
  height: 48px;
  justify-content: space-between;
`
export const DocumentList_list = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  min-width: 0;
  overflow-y: scroll;
  position: relative;
  border-radius: 5px;
  background: ${colors.bgLight};
  /* WebKit browsers (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.bgLighter};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.secondary};
    border-radius: 4px;
    border: 3px solid ${colors.bgLighter};
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.primaryDark};
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.secondary} ${colors.bgLighter};
`
const JobHistory = (props) => {
  const history = useSelector((state) => state?.jobHistory)
  const dispatch = useDispatch()
  const t = useTranslation('js_jobHistory')
  const { teamsUserCredential } = useContext(TeamsFxContext)

  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [user, setUser] = useState({})
  const Loading = [LoadingStates.FULFILLED, LoadingStates.REJECTED]

  useEffect(() => {
    const runEffect = async () => {
      const user = await teamsUserCredential.getUserInfo()
      user && setUser({ ...user, email: user?.preferredUserName })
    }
    runEffect()
  }, [])

  useEffect(() => {
    if (pageNo && pageSize) {
      dispatch(
        jobHistoryOverviewThunk({
          pageNo,
          pageSize,
        })
      )
    }
  }, [pageNo, pageSize])

  return (
    <Section>
      <DocumentList>
        <DocumentListHeader>Job History</DocumentListHeader>
        <DocumentList_list>
          <Pagination
            totalPages={history.totalPages}
            currentPage={history.currentPage}
            pageSize={pageSize}
            loading={!Loading.includes(history.loading)}
            onPageChanged={setPageNo}
            onPageSizeChanged={setPageSize}
          />
          {history.values?.map((job) => (
            <div
              className="card elevate--2"
              key={job.jobId}
              style={{
                opacity: !Loading.includes(history.loading) ? '0.5' : '1',
              }}
            >
              <div className="card__header">
                {job.jobType === 'wfTrigger' ? (
                  // <div>
                  //   {job.wfTriggerAutomationType}: {job.toolName}
                  // </div>
                  <>
                    <h3>{job.wfTriggerName}</h3>
                    <div>{formatDateTime(job.jobCreationDate)}</div>
                  </>
                ) : (
                  <>
                    <h3>{t(`lblTool_${job.toolName}`)}</h3>
                    <div>{formatDateTime(job.jobCreationDate)}</div>
                  </>
                )}
              </div>

              <div className="card__body">
                {job.inputFiles.length > 0 && (
                  <>
                    <h4>{t('lblInputFiles')}</h4>
                    <FileList
                      files={job.inputFiles}
                      toolName={job.toolName}
                      user={user}
                      loading={history?.loading}
                    />
                  </>
                )}
                {job.outputFiles.length > 0 && (
                  <>
                    <h4>{t('lblOutputFiles')}</h4>
                    <FileList
                      files={job.outputFiles}
                      toolName={job.toolName}
                      user={user}
                      loading={history?.loading}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </DocumentList_list>
      </DocumentList>
    </Section>
  )
}

export default withMessageBox(JobHistory)
