import { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'

// import { useTranslation } from '../hooks/TranslationContext'
import Dropdown from '../../controls/Dropdown'
import ComputerIcon from '../../controls/icons/computer'
// import HistoryIcon from '../../controls/icons/history'
// import DropboxIcon from '../../controls/icons/dropbox'
// import GoogleDriveIcon from '../../controls/icons/googleDrive'
// import OneDriveIcon from '../../controls/icons/oneDrive'
// import FolderIcon from '../../controls/icons/folder'
import AngleDownIcon from '../../controls/icons/angleDown'
// import HistoryFilePicker from '../../HistoryFilePicker'
// import MyDocPicker from '../../MyDocPicker'
import { useJobConfig } from '../JobConfig/JobContext'
import { useTranslation } from '../../i18n/useTranslation'
// import { DropContainerContext } from '../../../DropContainer'

export default (props) => {
  const { label, alignDropdown, color } = props
  const { fileUploadOpen } = useJobConfig()
  const t = useTranslation()
  //   const [historyFilePickerOpen, setHistoryFilePickerOpen] = useState(false)
  //   const [myDocPickerOpen, setMyDocPickerOpen] = useState(false)
  const [items, setItems] = useState([])
  //   const { user } = useSelector((state) => state.app)
  //   const { fileDropEnabled } = useSelector((state) => state.docProcessing)
  //   const fileDropContext = useContext(DropContainerContext)

  useEffect(() => {
    const _items = [
      {
        text: 'uploadLocalFile',
        icon: ComputerIcon,
        action: () => {
          fileUploadOpen()
        },
      },
    ]

    // if (user && user.isAuthenticated) {
    //   _items.push({
    //     text: t('addRecentlyUsedFile'),
    //     icon: HistoryIcon,
    //     action: () => {
    //       setHistoryFilePickerOpen(true)
    //     },
    //   })
    //   _items.push({
    //     text: t('addFromMyDoc'),
    //     icon: FolderIcon,
    //     action: () => {
    //       setMyDocPickerOpen(true)
    //     },
    //   })
    // }

    // _items.push({
    //   text: 'Dropbox',
    //   icon: DropboxIcon,
    //   action: () => {
    //     fileDropContext.openDropbox()
    //   },
    // })
    // _items.push({
    //   text: 'Google Drive',
    //   icon: GoogleDriveIcon,
    //   action: () => {
    //     fileDropContext.openGoogleDrive()
    //   },
    // })
    // _items.push({
    //   text: 'One Drive',
    //   icon: OneDriveIcon,
    //   action: () => {
    //     fileDropContext.openOneDrive()
    //   },
    // })

    setItems(_items)
  }, [])

  return (
    <>
      <Dropdown
        ariaLabel={t('fileDropActive')}
        // disabled={!fileDropEnabled}
        alignItems={alignDropdown}
        items={items?.map((e) => ({ ...e, text: t(e.text) }))}
        useButton={{
          color,
          endIcon: AngleDownIcon,
        }}
      >
        {label}
      </Dropdown>
      {/* {historyFilePickerOpen && (
        <HistoryFilePicker
          onClose={() => {
            setHistoryFilePickerOpen(false)
          }}
        />
      )} */}
      {/* {myDocPickerOpen && (
        <MyDocPicker
          onClose={() => {
            setMyDocPickerOpen(false)
          }}
        />
      )} */}
    </>
  )
}
